import { ColumnProps } from './Column.types'
import { ColumnRoot } from './Column.styles'
import React from 'react'

const Column = ({
    children,
    removeIfChildrenIsNull = false,
    cols = 'auto',
    xs,
    sm,
    md,
    lg,
    xl,
    xl2,
    offset,
    xsOffset,
    smOffset,
    mdOffset,
    lgOffset,
    xlOffset,
    xl2Offset,
    ...props
}: ColumnProps) => {
    if (removeIfChildrenIsNull && !children) {
        return null
    }

    return (
        <ColumnRoot
            $cols={cols}
            $xs={xs}
            $sm={sm}
            $md={md}
            $lg={lg}
            $xl={xl}
            $xl2={xl2}
            $offset={offset}
            $xsOffset={xsOffset}
            $smOffset={smOffset}
            $mdOffset={mdOffset}
            $lgOffset={lgOffset}
            $xlOffset={xlOffset}
            $xl2Offset={xl2Offset}
            {...props}
        >
            {children}
        </ColumnRoot>
    )
}

export default Column
