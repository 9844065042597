import { getColorFromAppState, makeTransitionCssClasses } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

const AutocompleteComboboxTransitionClasses = makeTransitionCssClasses('autocomplete-combobox',{
    leave: tw`transition ease-in duration-100`,
    leaveFrom: tw`opacity-100`,
    leaveTo: tw`opacity-0`
})

export const AutocompleteRoot = styled.div`
  ${tw`relative`}
  ${AutocompleteComboboxTransitionClasses}
`

export const AutocompleteItems = styled.ul<{
    $backgroundColor: AppColor,
    $focusColor: AppColor,
    $textColor: AppColor,
}>`
  ${tw`w-full flex flex-col max-h-48 bg-white rounded-xl border-2 overflow-y-auto`}
  border-color: ${({ $focusColor, ...props }) => {
        return getColorFromAppState({ $color: $focusColor, ...props })
    }};
  
  li {
    ${tw`text-small font-normal w-full`}
    color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ $color: $textColor, ...props })
    }};
    
    button {
      ${tw`py-3 px-3.5 w-full h-full text-left`}
      
      :hover, &.active, &.selected {
        background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ $color: $backgroundColor, ...props })
    }};
      }
    }
  }
`