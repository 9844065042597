import { FileUploadDialogFileListHeaderInfoProps } from './FIleUploadDialog.types'
import { FileUploadDialogFileListHeaderInfoRoot } from './FileUploadDialog.styles'
import { Trans, useTranslation } from 'next-i18next'
import DotIcon from '../../../assets/img/dot-icon.svg'
import React, { HTMLAttributes } from 'react'

const FileUploadDialogFileListHeaderInfo = ({
    done,
    totalFiles,
    getItemsByStatus,
    uploadingPercentage,
    ...props
}: FileUploadDialogFileListHeaderInfoProps) => {
    const { t } = useTranslation('common')

    const renderText = () => {
        if (done) {
            return (
                t('uploadDialog.subtitle.done', {
                    successFiles: getItemsByStatus?.('finished').length,
                    totalFiles
                })
            )
        }
        return (
            <Trans
                t={t}
                i18nKey='uploadDialog.subtitle.uploading'
                components={{ dot: <DotIcon tw='mx-2'/> }}
                values={{
                    uploadedFiles: getItemsByStatus?.('finished').length,
                    totalFiles,
                    uploadingPercentage
                }}
            />
        )
    }

    return (
        <FileUploadDialogFileListHeaderInfoRoot {...props as HTMLAttributes<HTMLSpanElement>}>
            {renderText()}
        </FileUploadDialogFileListHeaderInfoRoot>
    )
}

export default FileUploadDialogFileListHeaderInfo