import { getColorFromAppState } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const TabsRoot = styled.div`
  ${tw`flex items-center gap-2 overflow-x-auto whitespace-nowrap`}
  height: 3.313rem;
`

export const TabsItem = styled.button<{$active: boolean, $textColor: AppColor, $activeBackgroundColor: AppColor, $activeTextColor: AppColor}>`
  ${tw`flex items-center gap-2.5 py-3 px-4 rounded-xl text-normal font-medium h-full`}
  ${({ $active, $activeBackgroundColor, $activeTextColor, $textColor, ...props }) => {
        return $active ? `
    background-color: ${getColorFromAppState({ ...props, $color: $activeBackgroundColor })};
    color: ${getColorFromAppState({ ...props, $color: $activeTextColor })};
  ` : `
    background-color: transparent;
    color: ${getColorFromAppState({ ...props, $color: $textColor })};
  `
    }}
  
  :disabled {
    opacity: 0.6;
  }
`