import { DropdownItemButton, DropdownItemButtonIconContainer } from './Dropdown.styles'
import { DropdownItemProps } from './Dropdown.types'
import { Menu } from '@headlessui/react'
import { buildClassesWithDefault } from '../../../utils/Helper'
import React, { ElementType, ForwardedRef, forwardRef } from 'react'

const DropdownItem = <AsElement extends ElementType = 'button'>({
    children,
    as,
    className,
    disableHoverEffect = false,
    icon,
    ...props
}: DropdownItemProps<AsElement>, ref: ForwardedRef<AsElement>) => {
    return (
        <Menu.Item>
            {() => {
                return (
                    <DropdownItemButton
                        {...props}
                        className={buildClassesWithDefault('group', className)}
                        as={(as || 'button') as never}
                        ref={ref as unknown as ForwardedRef<HTMLButtonElement>}
                        $disableHoverEffect={disableHoverEffect}
                    >
                        {children}
                        {icon &&
                        <DropdownItemButtonIconContainer>
                            {icon}
                        </DropdownItemButtonIconContainer>
                        }
                    </DropdownItemButton>
                )
            }}
        </Menu.Item>
    )
}

export default forwardRef(DropdownItem)
