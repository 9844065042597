import { FileListTabType, FileUploadDialogFileListTabsProps } from './FIleUploadDialog.types'
import {
    FileUploadDialogFileListTabsRoot,
    FileUploadDialogFileListTabsStatusCountBadge
} from './FileUploadDialog.styles'
import { TabRender } from '../../base/tabs'
import { useTranslation } from 'next-i18next'
import FileIcon from '../customized-icons/FileIcon'
import React, { useState } from 'react'
import SimpleFileDoneIcon from '../customized-icons/SimpleFileDoneIcon'
import SimpleFileFailedIcon from '../customized-icons/SimpleFileFailedIcon'
import SimpleFileLoadingIcon from '../customized-icons/SimpleFileLoadingIcon'
import SimpleFileProcessingIcon from '../../../assets/img/simple-file-processing-icon.svg'

const FileUploadDialogFileListTabs = ({ onChange, allItemCounts, statusItemCounts }: FileUploadDialogFileListTabsProps) => {
    const { t } = useTranslation('common')

    const tabs: FileListTabType[] = [
        {
            name: t('uploadDialog.statuses.allLabel'),
            icon: FileIcon
        },
        {
            name: t('uploadDialog.statuses.list.finished'),
            icon: SimpleFileDoneIcon,
            value: 'finished'
        },
        {
            name: t('uploadDialog.statuses.list.processing'),
            icon: SimpleFileProcessingIcon,
            value: 'processing'
        },
        {
            name: t('uploadDialog.statuses.list.uploading'),
            icon: SimpleFileLoadingIcon,
            value: 'uploading'
        },
        {
            name: t('uploadDialog.statuses.list.failed'),
            icon: SimpleFileFailedIcon,
            value: 'failed'
        }
    ]

    const [tab, setTabState] = useState<number>(0)

    const renderTabItem: TabRender<FileListTabType> = (
        renderedTab,
        { textColor: color, active }
    ) => {
        return (
            <>
                <renderedTab.icon color={color}/>
                {renderedTab.name}
                <FileUploadDialogFileListTabsStatusCountBadge $active={active}>
                    {statusItemCounts?.[renderedTab.value] ?? allItemCounts}
                </FileUploadDialogFileListTabsStatusCountBadge>
            </>
        )
    }

    const setTab = (currentTab: number) => {
        setTabState(currentTab)
        onChange(tabs[currentTab]?.value)
    }

    return (
        <FileUploadDialogFileListTabsRoot
            items={tabs}
            selectedTab={tab}
            onSelectTab={setTab}
            renderTabItem={renderTabItem}
        />
    )
}

export default FileUploadDialogFileListTabs
