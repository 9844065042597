import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { ToastTypeColors } from './Toast.types'
import { getColorFromAppState } from '../../../utils/Helper'
import tw, { css, styled } from 'twin.macro'

export const ToastRoot = styled(ToastContainer).attrs({
    className: 'toast-container',
    toastClassName: 'toast',
    bodyClassName: 'body',
    progressClassName: 'progress'
})<{
    $typeColors: ToastTypeColors
}>`
  ${({ $typeColors, ...props }) => {
        return Object.entries($typeColors).map(([type, { textColor, backgroundColor, spinnerSecondaryColor }]) => {
            return css`
    .Toastify__toast--${type} {
      ${tw`flex items-start py-7 px-4 gap-2.5 rounded-xl max-w-max max-w-lg min-w-[16rem]`}
      color: ${getColorFromAppState({ ...props, $color: textColor })};
      background-color: ${getColorFromAppState({ ...props, $color: backgroundColor })};

      .Toastify__toast-icon svg {
        fill: ${getColorFromAppState({ ...props, $color: textColor })};
      }

      .Toastify__progress-bar {
        background-color: ${getColorFromAppState({ ...props, $color: textColor })};
      }
      
      .Toastify__spinner {
        border-color: ${getColorFromAppState({ ...props, $color: spinnerSecondaryColor })};
        border-right-color: ${getColorFromAppState({ ...props, $color: textColor })};
      }

      .body {
        ${tw`items-start`}
      }
    }
  `
        })
    }}
  
  button[aria-label="close"] {
    ${tw`hidden`}
  }
`