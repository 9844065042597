import { DefaultTheme, ThemedCssFunction } from 'styled-components'
import {
    RowAlign,
    RowDirection,
    RowGutter,
    RowJustify,
    RowResponsiveSize,
    RowWrap,
    SCRowProps,
    SCRowResponsiveSettings
} from './Row.types'
import tw, { TwStyle, css, styled, theme } from 'twin.macro'

export const RowDirectionStyles: Record<RowDirection, TwStyle> = {
    row: tw`flex-row`,
    column: tw`flex-col`
}

export const RowJustifyStyles: Record<RowJustify, TwStyle> = {
    start: tw`justify-start`,
    end: tw`justify-end`,
    center: tw`justify-center`,
    between: tw`justify-between`,
    around: tw`justify-around`,
    evenly: tw`justify-evenly`
}

export const RowAlignStyles: Record<RowAlign, TwStyle> = {
    start: tw`items-start`,
    end: tw`items-end`,
    center: tw`items-center`,
    stretch: tw`items-stretch`,
    baseline: tw`items-baseline`
}

export const RowWrapStyles: Record<RowWrap, TwStyle> = {
    wrap: tw`flex-wrap`,
    'wrap-reverse': tw`flex-wrap-reverse`,
    nowrap: tw`flex-nowrap`
}

export const RowGutterStyles: Record<'x' | 'y', Record<RowGutter, TwStyle>> = {
    x: {
        0: tw`gx-0`,
        1: tw`gx-1`,
        2: tw`gx-2`,
        3: tw`gx-3`,
        4: tw`gx-4`,
        5: tw`gx-5`
    },
    y: {
        0: tw`gy-0`,
        1: tw`gy-1`,
        2: tw`gy-2`,
        3: tw`gy-3`,
        4: tw`gy-4`,
        5: tw`gy-5`
    }
}

const getResponsiveSettings = ({ $justify, $align, $direction, $wrap, $gutter }: SCRowResponsiveSettings) => {
    return css`
  ${$justify && RowJustifyStyles[$justify]}
  ${$align && RowAlignStyles[$align]}
  ${$direction && RowDirectionStyles[$direction]}
  ${$wrap && RowWrapStyles[$wrap]}
  ${$gutter && css`${RowGutterStyles.x[$gutter.x]} ${RowGutterStyles.y[$gutter.y]}`}
`
}

const responsiveMedia: Record<RowResponsiveSize, (
    settings: SCRowResponsiveSettings
) => ReturnType<ThemedCssFunction<DefaultTheme>>> = {
    xs: (settings) => {
        return css`
      @media (min-width: ${theme`screens.xs`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    sm: (settings) => {
        return css`
      @media (min-width: ${theme`screens.sm`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    md: (settings) => {
        return css`
      @media (min-width: ${theme`screens.md`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    lg: (settings) => {
        return css`
      @media (min-width: ${theme`screens.lg`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    xl: (settings) => {
        return css`
      @media (min-width: ${theme`screens.xl`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    xl2: (settings) => {
        return css`
      @media (min-width: ${theme`screens.2xl`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    }
}

export const RowRoot = styled.div<SCRowProps>`
  ${tw`row`}
  ${({ $autoWidth }) => {
        return !$autoWidth ? tw`w-full` : ''
    }}
  ${({ $justify, $xsJustify, $smJustify, $mdJustify, $lgJustify, $xlJustify, $xl2Justify }) => {
        return css`
    ${RowJustifyStyles[$justify]}
    ${responsiveMedia.xs({ $justify: $xsJustify })}
    ${responsiveMedia.sm({ $justify: $smJustify })}
    ${responsiveMedia.md({ $justify: $mdJustify })}
    ${responsiveMedia.lg({ $justify: $lgJustify })}
    ${responsiveMedia.xl({ $justify: $xlJustify })}
    ${responsiveMedia.xl2({ $justify: $xl2Justify })}
  `
    }}

  ${({ $align, $xsAlign, $smAlign, $mdAlign, $lgAlign, $xlAlign, $xl2Align }) => {
        return css`
    ${RowAlignStyles[$align]}
    ${responsiveMedia.xs({ $align: $xsAlign })}
    ${responsiveMedia.sm({ $align: $smAlign })}
    ${responsiveMedia.md({ $align: $mdAlign })}
    ${responsiveMedia.lg({ $align: $lgAlign })}
    ${responsiveMedia.xl({ $align: $xlAlign })}
    ${responsiveMedia.xl2({ $align: $xl2Align })}
  `
    }}

  ${({ $direction, $xsDirection, $smDirection, $mdDirection, $lgDirection, $xlDirection, $xl2Direction }) => {
        return css`
    ${RowDirectionStyles[$direction]}
    ${responsiveMedia.xs({ $direction: $xsDirection })}
    ${responsiveMedia.sm({ $direction: $smDirection })}
    ${responsiveMedia.md({ $direction: $mdDirection })}
    ${responsiveMedia.lg({ $direction: $lgDirection })}
    ${responsiveMedia.xl({ $direction: $xlDirection })}
    ${responsiveMedia.xl2({ $direction: $xl2Direction })}
  `
    }}

  ${({ $wrap, $xsWrap, $smWrap, $mdWrap, $lgWrap, $xlWrap, $xl2Wrap }) => {
        return css`
    ${RowWrapStyles[$wrap]}
    ${responsiveMedia.xs({ $wrap: $xsWrap })}
    ${responsiveMedia.sm({ $wrap: $smWrap })}
    ${responsiveMedia.md({ $wrap: $mdWrap })}
    ${responsiveMedia.lg({ $wrap: $lgWrap })}
    ${responsiveMedia.xl({ $wrap: $xlWrap })}
    ${responsiveMedia.xl2({ $wrap: $xl2Wrap })}
  `
    }}

  ${({
        $gutterX,
        $gutterY,
        $xsGutterX,
        $xsGutterY,
        $smGutterX,
        $smGutterY,
        $mdGutterX,
        $mdGutterY,
        $lgGutterX,
        $lgGutterY,
        $xlGutterX,
        $xlGutterY,
        $xl2GutterX,
        $xl2GutterY
    }) => {
        return css`
    ${RowGutterStyles.x[$gutterX]}
    ${RowGutterStyles.y[$gutterY]}
    ${responsiveMedia.xs({ $gutter: { x: $xsGutterX, y: $xsGutterY } })}
    ${responsiveMedia.sm({ $gutter: { x: $smGutterX, y: $smGutterY } })}
    ${responsiveMedia.md({ $gutter: { x: $mdGutterX, y: $mdGutterY } })}
    ${responsiveMedia.lg({ $gutter: { x: $lgGutterX, y: $lgGutterY } })}
    ${responsiveMedia.xl({ $gutter: { x: $xlGutterX, y: $xlGutterY } })}
    ${responsiveMedia.xl2({ $gutter: { x: $xl2GutterX, y: $xl2GutterY } })}
  `
    }}
`



