import { FullScreenUploadAreaProps } from './FullScreenUploadArea.types'
import { FullScreenUploadAreaRoot, FullScreenUploadAreaTitle } from './FullScreenUploadArea.styles'
import FileUploadIcon from '../../app/customized-icons/FileUploadIcon'
import React, { useEffect, useState } from 'react'

const FullScreenUploadArea = ({
    onDrag,
    title,
    backgroundColor = 'white',
    titleColor = { value: 'primary', level: '400' },
    iconColor = { value: 'primary', level: '300' },
    iconArrowColor = { value: 'secondary', level: '400' }
}: FullScreenUploadAreaProps) => {
    const [showUploadArea, setShowUploadArea] = useState<boolean>(false)

    useEffect(() => {
        let lastTarget: HTMLElement

        const onDragEnter = ({ target }: DragEvent) => {
            lastTarget = target as HTMLElement
            setShowUploadArea(true)
        }

        const onDragLeave = ({ target }: DragEvent) => {
            return setShowUploadArea(!(target === lastTarget || target === document))
        }

        const onDragOver = (event: DragEvent) => {
            return event.preventDefault()
        }

        const onDropFiles = ({ dataTransfer: { files } }: DragEvent) => {
            event.preventDefault()
            onDrag(Array.from(files))
            setShowUploadArea(false)
        }

        //Register all drag & drop events
        window.addEventListener('dragenter', onDragEnter)
        window.addEventListener('dragleave', onDragLeave)
        window.addEventListener('dragover', onDragOver)
        window.addEventListener('drop', onDropFiles)

        return () => {
            //Unregister all drag & drop events
            window.removeEventListener('dragenter', onDragEnter)
            window.removeEventListener('dragleave', onDragLeave)
            window.removeEventListener('dragover', onDragOver)
            window.removeEventListener('drop', onDropFiles)
        }
    }, [onDrag])

    if (!showUploadArea) {
        return null
    }
    
    return (
        <FullScreenUploadAreaRoot $color={backgroundColor}>
            <FileUploadIcon 
                animated
                color={iconColor}
                arrowColor={iconArrowColor}
            />
            {title &&
                <FullScreenUploadAreaTitle $color={titleColor}>
                    {title}
                </FullScreenUploadAreaTitle>
            }
        </FullScreenUploadAreaRoot>
    )
}

export default FullScreenUploadArea
