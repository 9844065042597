import { WidthType } from './CustomButton.types'
import { getColorFromAppState, pxToRem } from '../../../utils/Helper'
import tw, { css, styled } from 'twin.macro'

const getIconColorStyles = (color: string) => {
    return css`
  span svg {
    fill: ${color};

    path {
      fill: ${color};
    }
  }
`
}

export const CustomButtonLeftIcon = styled.span`${tw`mr-2`}`
export const CustomButtonRightIcon = styled.span`${tw`ml-2`}`

export const CustomButtonRoot = styled.button<{
    $textColor: AppColor
    $backgroundColor: AppColor
    $borderColor: AppColor
    $disabledTextColor: AppColor
    $disabledBackgroundColor: AppColor
    $disabledBorderColor: AppColor
    $disabledOpacity: number
    $hoverTextColor: AppColor
    $hoverBackgroundColor: AppColor
    $hoverBorderColor: AppColor
    $focusOutlineColor: AppColor
    $width: WidthType | number
    $underlineText: boolean
    $customSvgColor: boolean
    $readOnly: boolean
    $loadingPrimaryColor: AppColor
    $loadingSecondaryColor: AppColor
}>`
  ${tw`inline-flex items-center justify-center px-4 py-4 whitespace-nowrap`}
  ${tw`rounded-xl border-2 outline-none hover:cursor-pointer`}
  ${tw`text-button font-medium`}
  ${({ $underlineText }) => {
        return $underlineText && tw`underline`
    }}

  ${({ $width }) => {
        switch ($width) {
            case 'full-width':
                return tw`w-full`
            case 'auto':
                return tw`w-auto`
            case 'default':
                return 'min-width: 12rem;'
            default:
                return `min-width: ${pxToRem($width)}rem;`
        }
    }}

  ${({ $focusOutlineColor, ...props }) => {
        return $focusOutlineColor !== 'transparent' && css`
    ${tw`focus:ring-2 focus:ring-offset-2`}
    --tw-ring-color: ${getColorFromAppState({ ...props, $color: $focusOutlineColor })};
  `
    }}

  color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $textColor })
    }};
  background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $backgroundColor })
    }};
  border-color: ${({ $borderColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $borderColor })
    }};

  ${({ $customSvgColor, $textColor, ...props }) => {
        return !$customSvgColor && getIconColorStyles(getColorFromAppState({ ...props, $color: $textColor }))
    }}
  :hover {
    ${({ $readOnly, $hoverTextColor, $hoverBackgroundColor, $hoverBorderColor, $customSvgColor, ...props }) => {
        return !$readOnly && css`
      color: ${getColorFromAppState({ ...props, $color: $hoverTextColor })};
      background-color: ${getColorFromAppState({ ...props, $color: $hoverBackgroundColor })};
      border-color: ${getColorFromAppState({ ...props, $color: $hoverBorderColor })};
      ${!$customSvgColor && getIconColorStyles(getColorFromAppState({ ...props, $color: $hoverTextColor }))}
    `
    }}
  }

  :disabled {
    ${tw`hover:cursor-auto`}
    ${({
        $readOnly,
        $disabledOpacity,
        $disabledTextColor,
        $disabledBackgroundColor,
        $disabledBorderColor,
        $customSvgColor,
        ...props
    }) => {
        return !$readOnly && css`
      opacity: ${$disabledOpacity};
      color: ${getColorFromAppState({ ...props, $color: $disabledTextColor })};
      background-color: ${getColorFromAppState({ ...props, $color: $disabledBackgroundColor })};
      border-color: ${getColorFromAppState({ ...props, $color: $disabledBorderColor })};
      ${!$customSvgColor && getIconColorStyles(getColorFromAppState({ ...props, $color: $disabledTextColor }))}`
    }}
  }

  span {
    ${tw`inline-flex items-center justify-center h-5`}
  }

  span svg {
    ${tw`w-6 h-6`}
    &.loading-icon {
      ${tw`animate-spin`}
      path.primary {
        fill: ${({ $loadingPrimaryColor, ...props }) => {
        return getColorFromAppState({
            ...props,
            $color: $loadingPrimaryColor
        })
    }} !important;
      }

      path.secondary {
        fill: ${({ $loadingSecondaryColor, ...props }) => {
        return getColorFromAppState({
            ...props,
            $color: $loadingSecondaryColor
        })
    }};
      }
    }
  }
`