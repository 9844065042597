import '../styles/Popover.scss'
import '../utils/Fonts'
import 'tailwindcss/tailwind.css'
import { AuthContextProvider } from '../auth/auth'
import { AuthenticationSettings, DeepNonRequired } from '@dactyl-npm-packages/dactyl-auth-react'
import { AxiosContext, AxiosProvider } from '../contexts/axios/AxiosContext'
import { EnvironmentProvider } from '../contexts/environment/EnvironmentContext'
import { GlobalStyles } from '../styles/GlobalStyles'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryClient } from '@tanstack/query-core'
import { QueryClientProvider } from '@tanstack/react-query'
import { Toast } from '../components/base'
import { appWithTranslation } from 'next-i18next'
import { environmentConfig } from '../config/environment/environmentConfig'
import FileUploadDialogProvider from '../components/app/file-upload-dialog/FileUploadDialogProvider'
import InvoiceUploadContextProvider from '../contexts/invoice-upload/InvoiceUploadContextProvider'
import NextNProgress from 'nextjs-progressbar'
import React from 'react'
import ThemeProvider from '../contexts/theme/ThemeProvider'
import config from '../config/config.json'
import type { AppProps } from 'next/app'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus: false
        },
        mutations: {
            retry: 0
        }
    }
})

const authConfig: DeepNonRequired<AuthenticationSettings> = {
    currentUserRoute: 'users/detail',
    signInRoute: 'auth/two-factor-authorization'
}

const App = ({ Component, pageProps }: AppProps) => {
    const recaptchaKey = pageProps?.environmentConfig?.recaptchaSiteKey
        ? pageProps?.environmentConfig?.recaptchaSiteKey
        : environmentConfig.recaptchaSiteKey
    return (
        <ThemeProvider theme={config.theme.colors}>
            <AxiosProvider baseURL={pageProps?.environmentConfig?.apiUrl || environmentConfig.apiUrl}>
                <AxiosContext.Consumer>
                    {({ axios }) => {
                        return (
                            <EnvironmentProvider config={pageProps?.environmentConfig || environmentConfig}>
                                <AuthContextProvider axiosInstance={axios} config={authConfig}>
                                    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
                                        <QueryClientProvider client={queryClient}>
                                            <InvoiceUploadContextProvider>
                                                <NextNProgress
                                                    color={config.theme.colors.primary['300']}
                                                    options={{ showSpinner: false }}
                                                />
                                                <GlobalStyles />
                                                <Component {...pageProps} />
                                                <FileUploadDialogProvider />
                                                <Toast />
                                            </InvoiceUploadContextProvider>
                                        </QueryClientProvider>
                                    </GoogleReCaptchaProvider>
                                </AuthContextProvider>
                            </EnvironmentProvider>
                        )
                    }}
                </AxiosContext.Consumer>
            </AxiosProvider>
        </ThemeProvider>
    )
}

export default appWithTranslation(App)