import { AutocompleteItems, AutocompleteRoot } from './Autocomplete.styles'
import { AutocompleteProps } from './Autocomplete.types'
import { Combobox } from '@headlessui/react'
import { Float } from '@headlessui-float/react'
import { buildClasses, getFloatingAutoSizeMiddleware } from '../../../utils/Helper'
import React from 'react'
import TextField from '../text-field/TextField'

const Autocomplete = <I extends unknown>({
    backgroundColor = { value: 'primary', level: '100' },
    textColor = { value: 'primary', level: '400' },
    focusColor = { value: 'primary', level: '300' },
    items = [],
    selectedItem,
    renderItem,
    itemKeyExtractor = (index) => {
        return index
    },
    getLabel,
    onSelectItem,
    ...props
}: AutocompleteProps<I>) => {
    const renderItems = () => {
        return (
            <Combobox.Options
                as={AutocompleteItems}
                $backgroundColor={backgroundColor}
                $focusColor={focusColor}
                $textColor={textColor}
            >
                {items.map((item, index) => {
                    return (
                        <Combobox.Option
                            as='li'
                            key={itemKeyExtractor(index, item)}
                            value={item}
                        >
                            {({ selected, active }) => {
                                return (
                                    <button type='button' className={buildClasses({ selected, active })}>
                                        {renderItem?.(index, item, active, selected) || getLabel(item)}
                                    </button>
                                )
                            }}
                        </Combobox.Option>
                    )
                })}
            </Combobox.Options>
        )
    }

    return (
        <Combobox as={AutocompleteRoot} value={selectedItem} onChange={onSelectItem}>
            <Float
                placement='bottom'
                strategy='fixed'
                offset={8}
                leave='autocomplete-combobox-transition-leave'
                leaveFrom='autocomplete-combobox-transition-leave-from'
                leaveTo='autocomplete-combobox-transition-leave-to'
                middleware={[getFloatingAutoSizeMiddleware()]}
            >
                <Combobox.Input
                    as={TextField}
                    {...props}
                    backgroundColor={backgroundColor}
                    focusColor={focusColor}
                    textColor={textColor}
                    displayValue={getLabel}
                />
                
                {renderItems()}
            </Float>
        </Combobox>
    )
}

export default Autocomplete
