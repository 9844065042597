import { getColorFromAppState } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const LinkRoot = styled.a<SCWithColorProps<{$underline: boolean, $bold: boolean}>>`
  ${tw`inline text-normal hover:cursor-pointer`}
  ${({ $bold }) => {
        return $bold ? tw`font-bold` : tw`font-medium`
    }}
  ${({ $underline }) => {
        return $underline && tw`underline`
    }}
  color: ${getColorFromAppState};
`