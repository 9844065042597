import { useEffect } from 'react'
import { useRouter } from 'next/router'

const useAskBeforeLeavePage = (message: string, disabled = false, allowedRoutes: string[] = []) => {
    const { events, locale } = useRouter()

    useEffect(() => {
        const routeChangeStartListener = (route: string) => {
            route = `${route.replace(`/${locale}`, '')}`
            if (allowedRoutes.find(item => {
                return route.toLowerCase().startsWith(item.toLowerCase())
            })) {
                return
            }
            if (!confirm(message)) {
                throw 'routeChangeStart/useAskBeforeLeavePage cancelled.'
            }
        }

        const beforeUnloadListener = (event: BeforeUnloadEvent) => {
            event.preventDefault()
            return (event.returnValue = message)
        }

        if (!disabled) {
            events.on('routeChangeStart', routeChangeStartListener)
            window.addEventListener('beforeunload', beforeUnloadListener)
        }

        return () => {
            if (!disabled) {
                events.off('routeChangeStart', routeChangeStartListener)
                window.removeEventListener('beforeunload', beforeUnloadListener)
            }
        }
    }, [events, disabled, message, allowedRoutes, locale])
}

export default useAskBeforeLeavePage