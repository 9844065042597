import {
    TextFieldContainer, TextFieldFloatingElements, TextFieldIconContainer,
    TextFieldInput,
    TextFieldLabel, TextFieldRoot,
    TextFieldValidationMessage
} from './TextField.styles'
import { TextFieldProps } from './TextField.types'
import { buildClassesWithDefault } from '../../../utils/Helper'
import React, { ForwardedRef, MouseEvent, forwardRef, useId, useState } from 'react'

const TextField = ({
    backgroundColor = { value: 'white' },
    borderColor,
    textColor = { value: 'primary', level: '400' },
    focusColor = { value: 'primary', level: '300' },
    children,
    label,
    secondaryLabel,
    labelVariant = 'inside',
    hiddenLabel = false,
    labelColor = { value: 'grey', level: '400' },
    secondaryLabelColor = textColor,
    invalidMessage,
    message,
    messageColor = { value: 'success', level: '400' },
    invalid = false,
    leftIcon,
    leftIconSize = 'md',
    onClickLeftIcon,
    rightIcon,
    rightIconSize = 'md',
    onClickRightIcon,
    id: idProp,
    containerClassName,
    ...props
}: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [isLeftIconHovered, setLeftIconHovered] = useState<boolean>(false)
    const [isRightIconHovered, setRightIconHovered] = useState<boolean>(false)

    const id = useId()
    const secondaryLabelVariant = labelVariant === 'outside'
        ? 'inside'
        : 'outside'

    const clickRightIcon = (event: MouseEvent<HTMLButtonElement>) => {
        onClickRightIcon?.(event)
    }

    const enterLeftIcon = () => {
        setLeftIconHovered(true)
    }

    const leaveLeftIcon = () => {
        setLeftIconHovered(false)
    }

    const enterRightIcon = () => {
        setRightIconHovered(true)
    }

    const leaveRightIcon = () => {
        setRightIconHovered(false)
    }

    const renderLeftIcon = () => {
        return (
            <TextFieldIconContainer
                $iconSize={leftIconSize}
                as={onClickLeftIcon ? 'button' : 'span'}
                onClick={onClickLeftIcon}
                onMouseOver={enterLeftIcon}
                onMouseOut={leaveLeftIcon}
            >
                {leftIcon}
            </TextFieldIconContainer>
        )
    }

    const renderRightIcon = () => {
        return rightIcon && (
            <TextFieldIconContainer
                $iconSize={rightIconSize}
                as={onClickRightIcon ? 'button' : 'span'}
                onClick={clickRightIcon}
                onMouseOver={enterRightIcon}
                onMouseOut={leaveRightIcon}
            >
                {rightIcon}
            </TextFieldIconContainer>
        )
    }

    const renderLabel = () => {
        return (
            <TextFieldLabel
                className='label'
                htmlFor={idProp || id}
                $color={labelColor}
                $srOnly={hiddenLabel}
                $variant={labelVariant}
            >
                {label}
            </TextFieldLabel>
        )
    }

    const renderSecondaryLabel = () => {
        return secondaryLabel && (
            <TextFieldLabel
                as='p'
                className='label'
                $color={secondaryLabelColor}
                $srOnly={false}
                $variant={secondaryLabelVariant}
            >
                {secondaryLabel}
            </TextFieldLabel>
        )
    }

    const renderInvalidMessages = () => {
        if (!invalidMessage) {
            return
        }
        if (Array.isArray(invalidMessage)) {
            return (
                <TextFieldValidationMessage>
                    <ul>
                        {invalidMessage.map((item, index) => {
                            return (
                                <li key={index}>{item}</li>
                            )
                        })}
                    </ul>
                </TextFieldValidationMessage>
            )
        }
        return (
            <TextFieldValidationMessage>
                {invalidMessage}
            </TextFieldValidationMessage>
        )
    }

    return (
        <TextFieldRoot $labelVariant={labelVariant} className={containerClassName}>
            {labelVariant === 'outside' && renderLabel()}
            {secondaryLabelVariant === 'outside' && renderSecondaryLabel()}
            <div className={buildClassesWithDefault({ 'text-left': labelVariant === 'upside' }, 'w-full')}>
                {labelVariant === 'upside' && renderLabel()}
                <TextFieldContainer>
                    <TextFieldFloatingElements $disablePointerEventsNone={isLeftIconHovered || isRightIconHovered}>
                        {renderLeftIcon()}
                        {renderRightIcon()}
                    </TextFieldFloatingElements>
                    <TextFieldInput
                        {...props}
                        id={idProp || id}
                        placeholder='Input'
                        ref={ref}
                        $invalid={invalid || !!invalidMessage}
                        $backgroundColor={backgroundColor}
                        $borderColor={borderColor}
                        $textColor={textColor}
                        $focusColor={focusColor}
                        $placeholderColor={labelColor}
                        $hasLeftIcon={!!leftIcon}
                        $leftIconSize={leftIconSize}
                        $hasRightIcon={!!rightIcon}
                        $rightIconSize={rightIconSize}
                    >
                        {children}
                    </TextFieldInput>
                    {labelVariant === 'inside' && renderLabel()}
                    {secondaryLabelVariant === 'inside' && renderSecondaryLabel()}
                </TextFieldContainer>
                {renderInvalidMessages()}
                {message &&
                    <TextFieldValidationMessage $color={messageColor}>
                        {message}
                    </TextFieldValidationMessage>
                }
            </div>
        </TextFieldRoot>
    )
}

export default forwardRef(TextField)
