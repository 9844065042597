import { FakeFileItem, FileUploadDialogFileListProps } from './FIleUploadDialog.types'
import {
    FileUploadDialogFileListHeader, FileUploadDialogFileListItemList,
    FileUploadDialogFileListRoot
} from './FileUploadDialog.styles'
import { FileUploadStatus, InvoiceUploadStateType } from '../../../hooks/invoice-upload/useInvoiceUpload.types'
import { calculatePercentage } from '../../../utils/Helper'
import FileUploadDialogFileListHeaderActions from './FileUploadDialogFileListHeaderActions'
import FileUploadDialogFileListHeaderInfo from './FileUploadDialogFileListHeaderInfo'
import FileUploadDialogFileListItem from './FileUploadDialogFileListItem'
import FileUploadDialogFileListTabs from './FileUploadDialogFileListTabs'
import React, { useCallback, useMemo, useState } from 'react'
import useInvoiceUploadContext from '../../../hooks/invoice-upload/useInvoiceUploadContext'

const FileUploadDialogFileList = ({
    onMinimize,
    draggableListeners,
    draggableAttributes,
    data,
    title
}: FileUploadDialogFileListProps) => {
    const [selectedTabStatus, setSelectedTabStatus] = useState<FileUploadStatus>()
    const totalFiles = data.length
    const { setFileDialogOpen } = useInvoiceUploadContext()

    const done = useMemo<boolean>(() => {
        return data.reduce((acc: boolean, currentValue: InvoiceUploadStateType) => {
            return acc && currentValue.status !== 'uploading'
        }, true)
    }, [data])

    const uploadingPercentage = useMemo<number>(() => {
        return calculatePercentage(
            data.reduce((acc: number, currentValue: InvoiceUploadStateType) => {
                return acc + currentValue.uploadPercentage
            }, 0),
            data.length * 100
        )
    },
    [data])

    const getItemsByStatus = useCallback((status: FileUploadStatus) => {
        return data.filter((item: InvoiceUploadStateType) => {
            return item.status === status
        })
    }, [data])

    const dataFilteredByStatus = useMemo<FakeFileItem[]>(() => {
        if (selectedTabStatus) {
            return getItemsByStatus(selectedTabStatus)
        }
        return data
    },
    [selectedTabStatus, getItemsByStatus, data])

    const closeDialog = () => {
        setFileDialogOpen(false)
    }

    const renderFileItems = () => {
        return dataFilteredByStatus.map((item: FakeFileItem) => {
            return (
                <FileUploadDialogFileListItem {...item} key={item.id}/>
            )
        })
    }

    return (
        <FileUploadDialogFileListRoot className='dialog-dragging-border'>
            <FileUploadDialogFileListHeader>
                <FileUploadDialogFileListHeaderActions
                    draggableListeners={draggableListeners}
                    draggableAttributes={draggableAttributes}
                    onMinimize={onMinimize}
                    onClose={closeDialog}
                    done={done}
                    title={title}
                />
                <FileUploadDialogFileListHeaderInfo
                    done={done}
                    totalFiles={totalFiles}
                    getItemsByStatus={getItemsByStatus}
                    uploadingPercentage={uploadingPercentage}
                />
            </FileUploadDialogFileListHeader>
            <FileUploadDialogFileListTabs
                onChange={setSelectedTabStatus}
                allItemCounts={data.length}
                statusItemCounts={{
                    uploading: getItemsByStatus('uploading').length,
                    processing: getItemsByStatus('processing').length,
                    failed: getItemsByStatus('failed').length,
                    finished: getItemsByStatus('finished').length
                }}
            />
            <FileUploadDialogFileListItemList>
                {renderFileItems()}
            </FileUploadDialogFileListItemList>
        </FileUploadDialogFileListRoot>
    )
}

export default FileUploadDialogFileList
