import {
    DialogContainer,
    DialogContent,
    DialogContentActions, DialogContentBody, DialogContentTitle,
    DialogOverlay,
    DialogRoot,
    DialogWindow
} from './Dialog.styles'
import { DialogProps } from './Dialog.types'
import { Dialog as ExternalDialog, Transition } from '@headlessui/react'
import CloseIcon from '../../../assets/img/close-icon.svg'
import React, { Fragment, useEffect } from 'react'

const Dialog = ({
    children,
    title,
    titleColor = { value: 'primary', level: '400' },
    actions,
    opened,
    width = 445,
    onClose,
    showCloseIcon,
    clickCloseIcon,
    ...props
}: DialogProps) => {

    const blockScroll = () => {
        document.body.style.overflow = 'hidden'
    }

    const unblockScroll = () => {
        document.body.style.overflow = 'auto'
    }

    useEffect(() => {
        if (opened) {
            blockScroll()
            return
        }
        unblockScroll()
    }, [opened])

    const renderContentTitle = () => {
        return (
            <ExternalDialog.Title as={DialogContentTitle} $color={titleColor}>
                {title}
            </ExternalDialog.Title>
        )
    }

    const renderContentBody = () => {
        return (
            <DialogContentBody>
                {typeof children === 'function' ? children({ close: onClose }) : children}
            </DialogContentBody>
        )
    }

    const renderContentActions = () => {
        return (
            <DialogContentActions $biggerMargin={!children}>
                {typeof actions === 'function' ? actions({ close: onClose }) : actions}
            </DialogContentActions>
        )
    }

    const renderOverlay = () => {
        return (
            <Transition.Child
                as={Fragment}
                enter='dialog-overlay-transition-enter'
                enterFrom='dialog-overlay-transition-enter-from'
                enterTo='dialog-overlay-transition-enter-to'
                leave='dialog-overlay-transition-leave'
                leaveFrom='dialog-overlay-transition-leave-from'
                leaveTo='dialog-overlay-transition-leave-to'
            >
                <DialogOverlay/>
            </Transition.Child>
        )
    }

    return (
        <Transition.Root show={opened} as={Fragment}>
            <ExternalDialog as={DialogRoot} onClose={onClose} {...props}>
                {renderOverlay()}
                <DialogContainer>
                    <DialogWindow>
                        <Transition.Child
                            as={Fragment}
                            enter='dialog-window-transition-enter'
                            enterFrom='dialog-window-transition-enter-from'
                            enterTo='dialog-window-transition-enter-to'
                            leave='dialog-window-transition-leave'
                            leaveFrom='dialog-window-transition-leave-from'
                            leaveTo='dialog-window-transition-leave-to'
                        >
                            <ExternalDialog.Panel as={DialogContent} $width={width}>
                                {showCloseIcon &&
                                    <div className='flex justify-end cursor-pointer -mt-5'>
                                        <CloseIcon onClick={clickCloseIcon} />
                                    </div>
                                }
                                {renderContentTitle()}
                                {renderContentBody()}
                                {renderContentActions()}
                            </ExternalDialog.Panel>
                        </Transition.Child>
                    </DialogWindow>
                </DialogContainer>
            </ExternalDialog>
        </Transition.Root>
    )
}

export default Dialog
