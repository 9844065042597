import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useCurrentUser } from '../../../auth/auth'
import ClientRender from '../client-render/ClientRender'
import FileUploadDialog from './FileUploadDialog'
import React from 'react'
import useInvoiceUploadContext from '../../../hooks/invoice-upload/useInvoiceUploadContext'

const FileUploadDialogProvider = () => {
    const { isLogged } = useCurrentUser()
    const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
    const sensors = useSensors(pointerSensor)
    const { isFileDialogOpen } = useInvoiceUploadContext()

    if (!isFileDialogOpen || !isLogged) {
        return
    }

    return (
        <ClientRender>
            <DndContext
                autoScroll
                id='file-upload-dialog'
                modifiers={[restrictToWindowEdges]}
                sensors={sensors}
            >
                <FileUploadDialog/>
            </DndContext>
        </ClientRender>
    )
}

export default FileUploadDialogProvider
