import { getColorFromAppState } from '../../../utils/Helper'
import { styled } from 'twin.macro'
import SimpleFileDoneIconOriginal from '../../../assets/img/simple-file-done-icon.svg'

const SimpleFileDoneIcon = styled(SimpleFileDoneIconOriginal).withConfig({
    shouldForwardProp: (prop) => {
        return !(['color', 'circleColor', 'checkColor'].includes(prop as string))
    }
})<{
    color?: AppColor
    circleColor?: AppColor
    checkColor?: AppColor
}>`
  path:not(.check) {
    stroke: ${({ color = { value: 'primary', level: '300' }, ...props }) => {
        return getColorFromAppState({ ...props, $color: color })
    }};
  }

  circle.circle {
    fill: ${({ circleColor = { value: 'success', level: '400' }, ...props }) => {
        return getColorFromAppState({ ...props, $color: circleColor })
    }};
  }
  
  path.check {
    fill: ${({ checkColor = 'white', ...props }) => {
        return getColorFromAppState({ ...props, $color: checkColor })
    }};
  }
`

export default SimpleFileDoneIcon

