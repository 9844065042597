import ApiQueryParser from './ApiQueryParser'

type ParamNames = {
    filters: string,
    fields: string,
    includes: string,
    appends: string,
    page: string,
    limit: string,
    sort: string
}

class ApiQueryBuilder {
    parser: ApiQueryParser
    paramNames: ParamNames

    sorts: string[]
    filters: object
    pageValue: number
    limitValue: number
    additionalParams?: object

    constructor(paramNames?: ParamNames) {
        if (!paramNames) {
            this.paramNames = {
                filters: 'filter',
                fields: 'fields',
                includes: 'include',
                appends: 'append',
                page: 'page',
                limit: 'limit',
                sort: 'sort'
            }
        }

        this.sorts = []
        this.filters = {}
        this.pageValue = 1
        this.limitValue = 15
        this.additionalParams = null
        this.parser = new ApiQueryParser(this)
    }

    reset() {
        this.parser.uri = ''
    }

    get() {
        return this.parser.parse()
    }

    /**
     * Query builder
     */

    where(key: string, value: string | number | boolean) {
        if (!key || !value) {
            throw new Error('The where() function takes 2 arguments both of string values.')
        }

        if (Array.isArray(value)) {
            throw new Error('The second argument to the where() function must be a string. Use whereIn() if you need to pass in an array.')
        }

        this.filters[key] = value
        return this
    }

    whereIn(key: string, array: string[]) {
        if (!key || !array) {
            throw new Error('The whereIn() function takes 2 arguments of (string, array).')
        }

        if (!key && Array.isArray(key) || typeof key === 'object') {
            throw new Error('The first argument for the whereIn() function must be a string or integer.')
        }

        if (!Array.isArray(array)) {
            throw new Error('The second argument for the whereIn() function must be an array.')
        }

        this.filters[key] = array.join(',')
        return this
    }

    fromNumber(key: string, value: number) {
        if (!key || !(typeof value === 'number')) {
            throw new Error('The fromNumber() function takes 2 arguments.')
        }

        this.filters[`${key}From`] = value
        return this
    }

    toNumber(key: string, value: number) {
        if (!key || !(typeof value === 'number')) {
            throw new Error('The toNumber() function takes 2 arguments.')
        }

        this.filters[`${key}To`] = value
        return this
    }

    sort(...args: string[]) {
        this.sorts = args
        return this
    }

    page(value: number) {
        if (!Number.isInteger(value)) {
            throw new Error('The page() function takes a single argument of a number')
        }
        this.pageValue = value
        return this
    }

    limit(value: number) {
        if (!Number.isInteger(value)) {
            throw new Error('The limit() function takes a single argument of a number.')
        }
        this.limitValue = value

        return this
    }

    params(params: object) {
        if (!params || params.constructor !== Object) {
            throw new Error('The params() function takes a single argument of an object.')
        }
        this.additionalParams = params
        return this
    }

}

export default ApiQueryBuilder
