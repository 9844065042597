import { getColorFromAppState } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const BannerRoot = styled.div<{ $backgroundColor: AppColor }>`
  ${tw`flex py-7 px-4 gap-2.5 rounded-xl`}
  background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $backgroundColor })
    }};
`

export const BannerText = styled.p<{ $textColor: AppColor }>`
  ${tw`text-large font-medium`}
  color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $textColor })
    }};
`

export const BannerIconContainer = styled.div`
  ${tw`w-6 h-6 relative top-[-1px]`}
`