import {
    CustomButtonLeftIcon,
    CustomButtonRightIcon,
    CustomButtonRoot
} from './CustomButton.styles'
import { CustomButtonProps } from './CustomButton.types'
import LoadingSpinIcon from '../../../assets/img/loading-spin-icon.svg'
import React, { ElementType, ForwardedRef, forwardRef } from 'react'

const CustomButton = <Element extends ElementType = 'button'>({
    as,
    children,
    textColor = { value: 'white' },
    backgroundColor = { value: 'primary', level: '300' },
    borderColor = backgroundColor,
    hoverTextColor = textColor,
    hoverBackgroundColor = { value: 'primary', level: '200' },
    hoverBorderColor = hoverBackgroundColor,
    disabledTextColor = textColor,
    disabledBackgroundColor = { value: 'primary', level: '200' },
    disabledBorderColor = disabledBackgroundColor,
    disabledOpacity = 0.6,
    focusOutlineColor = backgroundColor,
    loadingPrimaryColor = backgroundColor,
    loadingSecondaryColor = textColor,
    leftIcon,
    rightIcon,
    width = 'default',
    underlineText = false,
    customSvgColor = false,
    loading = false,
    disabled = false,
    readOnly = false,
    ...props
}: CustomButtonProps<Element>, ref: ForwardedRef<HTMLButtonElement>) => {
    const renderLeftIcon = () => {
        if (loading) {
            return (
                <CustomButtonLeftIcon>
                    <LoadingSpinIcon className='loading-icon'/>
                </CustomButtonLeftIcon>
            )
        }
        return leftIcon && (
            <CustomButtonLeftIcon>
                {leftIcon}
            </CustomButtonLeftIcon>
        )
    }

    return (
        <CustomButtonRoot
            {...props}
            as={as || 'button'}
            ref={ref}
            disabled={readOnly || disabled || loading}
            $textColor={textColor}
            $backgroundColor={backgroundColor}
            $borderColor={borderColor}
            $hoverTextColor={hoverTextColor}
            $hoverBackgroundColor={hoverBackgroundColor}
            $hoverBorderColor={hoverBorderColor}
            $disabledTextColor={disabledTextColor}
            $disabledBackgroundColor={disabledBackgroundColor}
            $disabledBorderColor={disabledBorderColor}
            $disabledOpacity={disabledOpacity}
            $focusOutlineColor={focusOutlineColor}
            $width={width}
            $underlineText={underlineText}
            $customSvgColor={customSvgColor}
            $readOnly={readOnly || loading}
            $loadingPrimaryColor={loadingPrimaryColor}
            $loadingSecondaryColor={loadingSecondaryColor}
        >
            {renderLeftIcon()}
            {children}
            {rightIcon &&
                <CustomButtonRightIcon>
                    {rightIcon}
                </CustomButtonRightIcon>
            }
        </CustomButtonRoot>
    )
}

export default forwardRef(CustomButton)
