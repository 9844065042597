import { ContainerProps } from './Container.types'
import { ContainerRoot } from './Container.styles'
import { isBoolean } from '../../../utils/Helper'
import React from 'react'


//Default activated spacing is only top spacing
//You can change/activate/deactivate top, left, right or bottom spacing separately
const Container = ({ children, spacing = 'lg', top = spacing, bottom, left, right, ...props }: ContainerProps) => {
    const makeSpacingProps = () => {
        const spacingProps = {}
        if (top) {
            spacingProps['$top'] = (isBoolean(top) ? spacing : top)
        }
        if (bottom) {
            spacingProps['$bottom'] = (isBoolean(bottom) ? spacing : bottom)
        }
        if (left) {
            spacingProps['$left'] = (isBoolean(left) ? spacing : left)
        }
        if (right) {
            spacingProps['$right'] = (isBoolean(right) ? spacing : right)
        }
        return spacingProps
    }

    return (
        <ContainerRoot {...props} {...makeSpacingProps()}>
            {children}
        </ContainerRoot>
    )
}

export default Container
