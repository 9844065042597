import { FileUploadStatus, InvoiceUploadStateType } from '../../hooks/invoice-upload/useInvoiceUpload.types'
import { Invoice } from '../../services/invoice/InvoiceService.types'
import { InvoiceUploadContextProviderProps, InvoiceUploadContextState } from './InvoiceUploadContext.types'
import { findFreeIdInArray } from '../../utils/Helper'
import { useCurrentUser } from '../../auth/auth'
import InvoiceUploadContext from './InvoiceUploadContext'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useInvoiceUpload from '../../hooks/api/invoice/useInvoiceUpload'
import useInvoiceUploadState from '../../hooks/invoice-upload/useInvoiceUploadState'

const InvoiceUploadContextProvider = ({ children }: InvoiceUploadContextProviderProps) => {
    const { isLogged } = useCurrentUser()
    const abortControllers = useRef<AbortController[]>([])
    const { mutateAsync, reset } = useInvoiceUpload()
    const [uploadingFiles, setUploadingFiles] = useInvoiceUploadState()
    const [isFileDialogOpen, setFileDialogOpen] = useState<boolean>(false)
    const [maximizeDialog, setMaximizeDialog] = useState<boolean>(false)
    const [uploadingStatus, setUploadingStatus] = useState<FileUploadStatus>()

    const determineUploadingStatus = useCallback(() => {
        const isSomeFileUploading = uploadingFiles.some((file: InvoiceUploadStateType) => {
            return file.status === 'uploading'
        })
        if (isSomeFileUploading) {
            return 'uploading'
        }
        const isSomeFileProcessing = uploadingFiles.some((file: InvoiceUploadStateType) => {
            return file.status === 'processing'
        })
        if (isSomeFileProcessing) {
            return 'processing'
        }
        const isSomeFileFailed = uploadingFiles.some((file: InvoiceUploadStateType) => {
            return file.status === 'failed'
        })
        if (isSomeFileFailed) {
            return 'failed'
        }
        return 'finished'
    }, [uploadingFiles])

    useEffect(() => {
        if (!isFileDialogOpen) {
            setMaximizeDialog(false)
        }
    }, [isFileDialogOpen])

    useEffect(() => {
        if (uploadingFiles?.length) {
            const targetUploadingStatus = determineUploadingStatus() as FileUploadStatus
            if (targetUploadingStatus) {
                setUploadingStatus(targetUploadingStatus)
            }
        }
        setFileDialogOpen(!!uploadingFiles?.length)
    }, [determineUploadingStatus, uploadingFiles])

    const removeInvoiceFromUploadingFiles = useCallback((invoiceId: string) => {
        setUploadingFiles((prev) => {
            return prev.filter((item: InvoiceUploadStateType) => {
                return item.invoiceId !== invoiceId
            })
        })
    }, [setUploadingFiles])


    const registerAbortController = useCallback((abortController: AbortController) => {
        return abortControllers.current = [...abortControllers.current, abortController]
    }, [abortControllers]
    )

    const unregisterAbortController = useCallback((abortController: AbortController) => {
        return abortControllers.current = abortControllers.current.filter((item: AbortController) => {
            return item !== abortController
        })
    }, [abortControllers]
    )

    const abortUploadingFiles = useCallback(() => {
        if (abortControllers?.current?.length) {
            abortControllers.current.forEach((item: AbortController) => {
                return item.abort()
            })
        }
        reset()
    }, [abortControllers, reset])

    useEffect(() => {
        if (!isLogged) {
            abortUploadingFiles()
            setUploadingFiles([])
        }
    }, [abortUploadingFiles, isLogged, setUploadingFiles])

    const uploadFiles = useCallback<InvoiceUploadContextState['uploadFiles']>((...files: File[]) => {
        setMaximizeDialog(false)
        files.forEach((file: File) => {
            setUploadingFiles((prev) => {
                const existsFile = prev.find((item: InvoiceUploadStateType) => {
                    return item.file === file
                })
                const fileId = existsFile
                    ? existsFile.id
                    : findFreeIdInArray(prev, 'id')
                const abortController = new AbortController()
                registerAbortController(abortController)
                const signal = abortController.signal
                mutateAsync({
                    file: file,
                    signal,
                    onUploadProgress: (uploadPercentage: number) => {
                        return setUploadingFiles((firstPrev?: InvoiceUploadStateType[]) => {
                            return firstPrev.map((item: InvoiceUploadStateType) => {
                                return item.id === fileId
                                    ? ({ ...item, uploadPercentage, status: 'uploading' })
                                    : item
                            })
                        })
                    }
                })
                    .then((invoice: Invoice) => {
                        return setUploadingFiles((secondPrev?: InvoiceUploadStateType[]) => {
                            return secondPrev.map((item: InvoiceUploadStateType) => {
                                return item.id === fileId
                                    ? ({ ...item, invoiceId: invoice.id, status: 'processing' })
                                    : item
                            })
                        })
                    })
                    .catch(() => {
                        return setUploadingFiles((thirdPrev?: InvoiceUploadStateType[]) => {
                            return thirdPrev.map((item: InvoiceUploadStateType) => {
                                return item.id === fileId
                                    ? ({ ...item, status: 'failed' })
                                    : item
                            })
                        })
                    })
                    .finally(() => {
                        return unregisterAbortController(abortController)
                    })
                if (existsFile) {
                    return prev
                }
                return [
                    ...prev,
                    {
                        id: fileId,
                        title: file.name,
                        status: 'uploading',
                        uploadPercentage: 0,
                        file
                    }
                ]
            })
        })
    }, [mutateAsync, registerAbortController, setUploadingFiles, unregisterAbortController])

    const retryFileUpload = useCallback((id: number) => {
        const uploadingFile = uploadingFiles.find((item: InvoiceUploadStateType) => {
            return item.id === id
        })
        uploadingFile && uploadingFile.status !== 'finished' && uploadFiles(uploadingFile.file)
    }, [uploadFiles, uploadingFiles])

    const deleteFile = useCallback((id: number) => {
        return setUploadingFiles((prev) => {
            return prev.filter(item => {
                return item.status !== 'uploading' && item.id !== id
            })
        })
    }, [setUploadingFiles])

    const setUploadingFileStatus = useCallback((id: number, status: FileUploadStatus) => {
        setUploadingFiles((prevUploadingFiles?: InvoiceUploadStateType[]) => {
            return prevUploadingFiles.map((uploadingFile: InvoiceUploadStateType) => {
                return uploadingFile.id === id
                    ? { ...uploadingFile, status: status }
                    : uploadingFile
            })
        })
    }, [setUploadingFiles])

    return (
        <InvoiceUploadContext.Provider
            value={{
                uploadFiles,
                retryFileUpload,
                deleteFile,
                isFileDialogOpen,
                setFileDialogOpen,
                setUploadingFileStatus,
                removeInvoiceFromUploadingFiles,
                maximizeDialog,
                setMaximizeDialog,
                uploadingStatus,
                setUploadingStatus
            }}
        >
            {children}
        </InvoiceUploadContext.Provider>
    )
}

export default InvoiceUploadContextProvider