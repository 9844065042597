import { css, styled } from 'twin.macro'
import { getColorFromAppState } from '../../../utils/Helper'
import { keyframes } from 'styled-components'
import FileUploadOriginalIcon from '../../../assets/img/file-upload-icon.svg'

const FileUploadIconAnimationKeyframes = keyframes`
  0%   { transform: translateY(0); }
  30%  { transform: translateY(-25px); }
  50%  { transform: translateY(0); }
  100% { transform: translateY(0); }
`

const FileUploadIcon = styled(FileUploadOriginalIcon).withConfig({
    shouldForwardProp: (prop) => {
        return !(['color', 'arrowColor', 'animated'].includes(prop as string))
    }
})<{
    color?: AppColor
    arrowColor?: AppColor
    animated?: boolean
}>`
  path:not(.arrow) {
    fill: ${({ color = { value: 'primary', level: '300' }, ...props }) => {
        return getColorFromAppState({ ...props, $color: color })
    }};
  }

  path.arrow {
    fill: ${({ arrowColor = { value: 'secondary', level: '400' }, ...props }) => {
        return getColorFromAppState({ ...props, $color: arrowColor })
    }};
    ${({ animated }) => {
        return animated && css`animation: ${FileUploadIconAnimationKeyframes} 2s ease infinite;`
    }}
  }
`

export default FileUploadIcon
