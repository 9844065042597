import { Card } from '../card'
import tw, { styled } from 'twin.macro'

export const CenteredFormCardRoot = styled(Card)`
  ${tw`text-center flex justify-center`}
`

export const CenteredFormCardContainer = styled.div`
  ${tw`max-w-lg`}
`

export const CenteredFormCardHeader = styled.span`
  ${tw`mx-auto flex justify-center`}
`