import { CSS } from '@dnd-kit/utilities'
import { DragEndEvent, useDndMonitor, useDraggable } from '@dnd-kit/core'
import { FileUploadDialogRoot } from './FileUploadDialog.styles'
import { InvoiceUploadStateType } from '../../../hooks/invoice-upload/useInvoiceUpload.types'
import { fixElementCollisions, remToPx } from '../../../utils/Helper'
import { mergeRefs } from 'react-merge-refs'
import { useTranslation } from 'next-i18next'
import FileUploadDialogFileList from './FileUploadDialogFileList'
import FileUploadDialogShortInfo from './FileUploadDialogShortInfo'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useAskBeforeLeavePage from '../../../hooks/useAskBeforeLeavePage'
import useInvoiceUploadContext from '../../../hooks/invoice-upload/useInvoiceUploadContext'
import useInvoiceUploadState from '../../../hooks/invoice-upload/useInvoiceUploadState'

const FileUploadDialog = () => {
    const { maximizeDialog, setMaximizeDialog, uploadingStatus, isFileDialogOpen } = useInvoiceUploadContext()
    const dialogRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation('common')
    const [uploadingFiles] = useInvoiceUploadState()
    const [scrollY, setScrollY] = useState(window.scrollY)
    const [{ x, y }, setDialogCoordinates] = useState<{x: number, y: number}>({
        x: (window.innerWidth - remToPx(24)) - 50,
        y: (window.innerHeight - remToPx(14)) - 50
    })

    const title = useMemo<string>(() => {
        if (uploadingStatus === 'uploading') {
            return t('uploadDialog.title.uploading')
        }
        if (uploadingStatus == 'processing') {
            return t('uploadDialog.title.processing')
        }
        if (uploadingStatus === 'failed') {
            return t('uploadDialog.title.failed')
        }
        if (uploadingStatus === 'finished') {
            return t('uploadDialog.title.finished')
        }
    }, [t, uploadingStatus])

    useEffect(() => {
        const changeInitialValues = () => {
            const newCoordinates = {
                x: (window.innerWidth - remToPx(24)) - 50,
                y: (window.innerHeight - remToPx(14)) - 50
            }
            setDialogCoordinates(newCoordinates)
            setScrollY(window.scrollY)
        }

        changeInitialValues()
    }, [maximizeDialog, isFileDialogOpen])

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])


    const { setNodeRef, transform, listeners, attributes, isDragging } = useDraggable({
        id: 'file-upload-dialog'
    })

    useDndMonitor({
        onDragEnd: ({ delta: { x: deltaX, y: deltaY } }: DragEndEvent) => {
            return setDialogCoordinates({
                x: x + deltaX,
                y: y + deltaY
            })
        }
    })

    const disabledAskBeforeLeavePage = useMemo<boolean>(() => {
        return !uploadingFiles.find((item: InvoiceUploadStateType) => {
            return item.status === 'uploading'
        })
    }, [uploadingFiles])

    useAskBeforeLeavePage(t('uploadDialog.askBeforeLeavePage'), disabledAskBeforeLeavePage, ['/dashboard'])

    useEffect(() => {
        if (!dialogRef.current) {
            return
        }
        const fixDialogCollisions = () => {
            setDialogCoordinates(({ x: coordinateX, y: coordinateY }) => {
                return fixElementCollisions(
                    coordinateX,
                    coordinateY,
                    window.innerWidth,
                    window.innerHeight,
                    dialogRef.current.clientWidth,
                    dialogRef.current.clientHeight
                )
            }
            )
        }
        fixDialogCollisions()
        window.addEventListener('resize', fixDialogCollisions)
        return () => {
            return window.removeEventListener('resize', fixDialogCollisions)
        }
    }, [maximizeDialog])

    const onMinimizeDialog = () => {
        setMaximizeDialog(false)
    }

    const onMaximizeDialog = () => {
        setMaximizeDialog(true)
    }

    const dialogStyle = transform && {
        transform: CSS.Translate.toString(transform)
    }

    const renderContent = () => {
        if (maximizeDialog) {
            return (
                <FileUploadDialogFileList
                    title={title}
                    draggableListeners={listeners}
                    draggableAttributes={attributes}
                    onMinimize={onMinimizeDialog}
                    data={uploadingFiles}
                />
            )
        }
        return (
            <FileUploadDialogShortInfo
                title={title}
                draggableListeners={listeners}
                draggableAttributes={attributes}
                data={uploadingFiles}
                onMaximize={onMaximizeDialog}
                uploadingStatus={uploadingStatus}
            />
        )
    }

    return (
        <FileUploadDialogRoot
            {...listeners}
            {...attributes}
            ref={mergeRefs([dialogRef, setNodeRef])}
            style={dialogStyle}
            $x={x}
            $y={y + scrollY}
            $isDragging={isDragging}
        >
            {renderContent()}
        </FileUploadDialogRoot>
    )
}

export default FileUploadDialog
