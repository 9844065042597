import { getColorFromAppState } from '../../../utils/Helper'
import { styled } from 'twin.macro'
import FileOriginalIcon from '../../../assets/img/file-icon.svg'

const FileIcon = styled(FileOriginalIcon).withConfig({
    shouldForwardProp: (prop) => {
        return !(['color'].includes(prop as string))
    }
})<{
    color?: AppColor
}>`
  path {
    stroke: ${({ color = { value: 'primary', level: '300' }, ...props }) => {
        return getColorFromAppState({ ...props, $color: color })
    }};
  }
`

export default FileIcon
