import { DropdownGroupProps } from './Dropdown.types'
import { DropdownGroupRoot } from './Dropdown.styles'
import React from 'react'

const DropdownGroup = ({ children, ...props }: DropdownGroupProps) => {
    return (
        <DropdownGroupRoot {...props}>
            {children}
        </DropdownGroupRoot>
    )
}

export default DropdownGroup
