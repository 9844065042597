import { EnvironmentConfig } from './environmentConfig.types'
import getNextConfig from 'next/config'

const { publicRuntimeConfig } = getNextConfig()

const getConfig = () => {
    if (!publicRuntimeConfig) {
        console.error('[ERROR]: env config not exists')
    }
    return publicRuntimeConfig
}

export const environmentConfig = getConfig() as EnvironmentConfig