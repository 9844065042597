import { Tabs } from '../../base/tabs'
import tw, { styled } from 'twin.macro'

export const FileUploadDialogRoot = styled.div<{ $x: number, $y: number, $isDragging: boolean }>`
  ${tw`absolute rounded-2xl z-40`}
  ${({ $x, $y }) => {
        return `
   top: ${$y}px;
   left: ${$x}px;
  `
    }}
  
  .dialog-dragging-border {
    ${({ $isDragging }) => {
        return $isDragging && tw`border-primary-200`
    }}
  }
`

export const FileUploadDialogShortInfoRoot = styled.div<{ $smallerPadding: boolean }>`
  ${tw`box-border border-4 border-transparent bg-primary-400 w-96 rounded-xl pt-5 pr-4 pl-5`}
  ${({ $smallerPadding }) => {
        return $smallerPadding ? tw`pb-10` : tw`pb-14`
    }}
`

export const FileUploadDialogShortInfoHeader = styled.div`
  ${tw`flex items-center justify-between`}
`

export const FileUploadDialogShortInfoHeaderTitleContainer = styled.div`
  ${tw`flex items-center`}
`

export const FileUploadDialogShortInfoHeaderTitle = styled.h6`
  ${tw`ml-2 font-bold text-large text-white`}
`

export const FileUploadDialogShortInfoHeaderActionsContainer = styled.div`
  ${tw`flex items-center gap-2.5`}
`

export const FileUploadDialogShortInfoHeaderDescription = styled.span`
  ${tw`text-small font-medium mt-2.5 text-white flex items-center`}
`

export const FileUploadDialogShortInfoStatusDescription = styled.p`
  ${tw`mt-12 text-white text-normal font-medium flex items-center gap-2.5`}
`

export const FileUploadDialogFileListRoot = styled.div`
  ${tw`bg-white box-border border-4 border-transparent rounded-xl p-5 pb-10 shadow-lg`}
  width: 66rem;
`

export const FileUploadDialogFileListHeader = styled.div`
  ${tw`bg-primary-400 rounded-xl w-full p-4`}
`

export const FileUploadDialogFileListHeaderActionsRoot = styled.div`
  ${tw`flex items-center justify-between`}
`

export const FileUploadDialogFileListHeaderActionsTitleContainer = styled.div`
  ${tw`flex items-center`}
`

export const FileUploadDialogFileListHeaderActionsTitle = styled.h6`
  ${tw`ml-2 font-bold text-large text-white`}
`

export const FileUploadDialogFileListHeaderActionsContainer = styled.div`
  ${tw`flex items-center gap-2.5`}
`

export const FileUploadDialogFileListHeaderInfoRoot = styled.span`
  ${tw`text-small font-medium mt-2.5 text-white flex items-center`}
`

export const FileUploadDialogFileListTabsRoot = styled(Tabs)`
  ${tw`mt-5 justify-between`}
`

export const FileUploadDialogFileListTabsStatusCountBadge = styled.span<{$active: boolean}>`
  ${tw`flex items-center justify-center text-primary-300 flex items-center justify-center text-small font-medium`}
  ${({ $active }) => {
        return $active ? tw`bg-white` : tw`bg-grey-100`
    }}
  border-radius: 1.875rem;
  width: 2.375rem;
  height: 2.375rem;
`

export const FileUploadDialogFileListItemList = styled.div`
  ${tw`flex flex-wrap mt-5 max-h-96 overflow-auto -m-3 !mt-5`}
`

export const FileUploadDialogFileListItemRoot = styled.div`
  ${tw`flex items-center gap-2 w-1/2 p-3`}
`

export const FileUploadDialogFileListItemInfo = styled.div`
  ${tw`flex flex-col gap-1 w-full`}
`

export const FileUploadDialogFileListItemInfoTitleContainer = styled.div`
  ${tw`flex justify-between items-center relative`}
`

export const FileUploadDialogFileListItemInfoTitle = styled.h6`
  ${tw`text-normal font-medium`}
`

export const FileUploadDialogFileListItemInfoTitleStatus = styled.div`
  ${tw`flex items-center gap-2 absolute right-0 -top-1`}
`

export const FileUploadDialogFileListItemInfoSubtitle = styled.small<{$failed: boolean}>`
  ${tw`text-small font-normal`}
  ${({ $failed }) => {
        return $failed ? tw`text-error-400` : tw`text-grey-500`
    }}
`