import { GridProps } from './Grid.types'
import { GridRoot } from './Grid.styles'
import React from 'react'

const Grid = ({ children, ...props }: GridProps) => {
    return (
        <GridRoot {...props}>
            {children}
        </GridRoot>
    )
}

export default Grid
