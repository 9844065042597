import { AxiosError, AxiosProgressEvent } from 'axios'
import { Invoice } from '../../../services/invoice/InvoiceService.types'
import { uploadInvoiceFile } from '../../../services/invoice/InvoiceService'
import { useCurrentUser } from '../../../auth/auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const useInvoiceUpload = () => {
    const { isLogged } = useCurrentUser()
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()
    
    return useMutation<Invoice, AxiosError, { file: File, onUploadProgress?: (percentage: number) => void, signal?: AbortSignal }>(
        ['invoiceUpload'], 
    async ({ file, onUploadProgress, signal }) => {
        return await uploadInvoiceFile(axiosInstance, file, {
            onUploadProgress: ({ loaded, total }: AxiosProgressEvent) => {
                return onUploadProgress && onUploadProgress(Math.round(
                    (loaded * 100) / total))
            },
            signal
        })
    },
    {
        onSuccess: async (data) => {
            if (!isLogged) {
                return
            }
            queryClient.setQueryData(['invoice', data.id], data)
            //Update data in pagination list
            queryClient.setQueriesData<MakeAxiosPaginatedResponse<Invoice>>(['invoices'], (prev) => {
                if (prev.data.length === prev.meta.perPage) {
                    return prev
                }
                const newItem = {
                    ...data,
                    status: 'processing'
                } as Invoice
                return {
                    ...prev,
                    data: [newItem, ...prev.data]
                }
            })
        }
    })
}

export default useInvoiceUpload