import { ThemeProvider as ThemeProviderExternal } from 'styled-components'
import { ThemeProviderProps } from './ThemeProvider.types'
import React, { PropsWithChildren } from 'react'
import colors from 'tailwindcss/colors'

const ThemeProvider = ({ children, theme: themeProp, ...props }: PropsWithChildren<ThemeProviderProps>) => {
    return (
        <ThemeProviderExternal {...props} theme={{ colors: { ...colors, ...themeProp } }}>
            {children}
        </ThemeProviderExternal>
    )
}

export default ThemeProvider
