import { getColorFromAppState } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const NumberCardRoot = styled.div<{
  $textColor: AppColor
  $backgroundColor: AppColor
}>`
  ${tw`rounded-xl p-4 rounded-xl`}
  color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $textColor })
    }};
  background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $backgroundColor })
    }};
  width: 19.688rem;
`

export const NumberCardRing = styled.span<{
  $ringBackgroundColor: AppColor
  $ringBorderColor: AppColor
  $ringTextColor: AppColor
}>`
  ${tw`inline-flex justify-center items-center rounded-full w-8 h-8 text-normal font-normal border`}
  color: ${({ $ringTextColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $ringTextColor })
    }};
  background-color: ${({ $ringBackgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $ringBackgroundColor })
    }};
  border-color: ${({ $ringBorderColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $ringBorderColor })
    }};
`

export const NumberCardText = styled.p<{
  $color: AppColor
}>`
  ${tw`mt-2 text-normal font-normal leading-6`}
`