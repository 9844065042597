import { BannerIconContainer, BannerRoot, BannerText } from './Banner.styles'
import { BannerProps } from './Banner.types'
import React from 'react'

const Banner = ({
    children,
    icon,
    textColor = { value: 'success', level: '400' },
    backgroundColor = { value: 'success', level: '100' },
    ...props
}: BannerProps) => {
    return (
        <BannerRoot {...props} $backgroundColor={backgroundColor}>
            {icon && (
                <BannerIconContainer className='w-6 h-6'>
                    {icon}
                </BannerIconContainer>
            )}
            <BannerText $textColor={textColor}>
                {children}
            </BannerText>
        </BannerRoot>
    )
}

export default Banner
