import { NumberCardProps } from './NumberCard.types'
import { NumberCardRing, NumberCardRoot, NumberCardText } from './NumberCard.styles'
import React from 'react'

const NumberCard = ({ 
    children,
    number,
    textColor = { value: 'primary', level: '400' },
    backgroundColor = { value: 'grey', level: '100' },
    ringTextColor = 'white',
    ringBackgroundColor = { value: 'primary', level: '300' },
    ringBorderColor = ringBackgroundColor,
    ...props
}: NumberCardProps) => {
    return (
        <NumberCardRoot
            {...props}
            $textColor={textColor}
            $backgroundColor={backgroundColor}
        >
            <NumberCardRing
                $ringTextColor={ringTextColor}
                $ringBackgroundColor={ringBackgroundColor}
                $ringBorderColor={ringBorderColor}
            >
                {number}
            </NumberCardRing>
            <NumberCardText $color={textColor}>
                {children}
            </NumberCardText>
        </NumberCardRoot>
    )
}

export default NumberCard
