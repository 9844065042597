import { SelectIconSize } from './Select.types'
import { TextFieldIconSize } from '../text-field/TextField.types'
import { getColorFromAppState, makeTransitionCssClasses } from '../../../utils/Helper'
import tw, { css, styled } from 'twin.macro'

const SelectTransitionClasses = makeTransitionCssClasses('select', {
    leave: tw`transition ease-in duration-100`,
    leaveFrom: tw`opacity-100`,
    leaveTo: tw`opacity-0`
})

export const SelectRoot = styled.div`
  ${tw`relative w-full`}
  ${SelectTransitionClasses}
`

export const SelectFloatingElements = styled.div`
  ${tw`px-4 absolute h-full w-full flex items-center justify-between pointer-events-none`}
`

export const SelectItems = styled.ul<{
    $backgroundColor: AppColor
    $focusColor: AppColor
    $textColor: AppColor
}>`
  ${tw`flex flex-col max-h-48 bg-white rounded-xl border-2 overflow-y-auto`}
  border-color: ${({ $focusColor, ...props }) => {
        return getColorFromAppState({ $color: $focusColor, ...props })
    }};

  li {
    ${tw`text-small font-normal w-full`}
    color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ $color: $textColor, ...props })
    }};

    button {
      ${tw`py-3 px-3.5 w-full h-full text-left`}
      :hover, &.active, &.selected {
        background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ $color: $backgroundColor, ...props })
    }} !important;
      }
    }
  }
`

const SelectButtonActiveStyles = css<{$focusColor: AppColor}>`
  ${tw`ring-2 !border-transparent`}
  --tw-ring-color: ${({ $focusColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $focusColor })
    }};
`

export const SelectButton = styled.button<{
    $backgroundColor: AppColor
    $focusColor: AppColor
    $textColor: AppColor
    $borderColor?: AppColor
    $invalid: boolean
    $hasLeftIcon: boolean
    $leftIconSize: TextFieldIconSize
    $hasRightIcon: boolean
    $rightIconSize: TextFieldIconSize
    $active: boolean
}>`
  ${tw`py-3.5 px-4 rounded-xl w-full text-small font-normal border-2 border-transparent outline-none text-left`}
  ${({ $hasLeftIcon, $leftIconSize }) => {
        return $hasLeftIcon && ($leftIconSize === 'sm' ? tw`!pl-9` : tw`!pl-11`)
    }}
  ${({ $hasRightIcon, $rightIconSize }) => {
        return $hasRightIcon && ($rightIconSize === 'sm' ? tw`!pr-9` : tw`!pr-11`)
    }}
  
  min-height: 3rem;
  color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $textColor })
    }};
  background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $backgroundColor })
    }};
  border-color: ${({ $borderColor, ...props }) => {
        return $borderColor ?
            getColorFromAppState({ ...props, $color: $borderColor }) : 'transparent'
    }};

  ${({ $active }) => {
        return $active && SelectButtonActiveStyles
    }}
  :focus {
    ${SelectButtonActiveStyles}
  }
  
  :disabled {
    opacity: 0.6;
  }

  ${({ $invalid }) => {
        return $invalid && tw`!text-error-400 !border-transparent ring-2 !ring-error-400`
    }}

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SelectValidationMessage = styled.strong`
  ${tw`flex mt-1 ml-2 text-small font-medium text-error-400`}
`

export const SelectIconContainer = styled.span<{$iconSize: SelectIconSize}>`
  ${tw`inline-flex items-center justify-center`}
  ${({ onClick }) => {
        return !!onClick && tw`pointer-events-auto`
    }}
  svg {
    ${({ $iconSize }) => {
        return $iconSize === 'sm' ? tw`h-4 w-4` : tw`h-6 w-6`
    }}
  }
`