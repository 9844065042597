import { useCallback } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const useGlobalState = <D = unknown>(key: string, initialData?: D): [D, (newState?: D | ((prev?: D) => D)) => void] => {
    const { data } = useQuery<D>([key], () => {
        return initialData
    }, { enabled: false, initialData })
    const client = useQueryClient()

    const setData = useCallback((newState?: D | ((prev?: D) => D)) => {
        client.setQueryData<D>([key], newState)
    }, [client, key])
        
    return [
        data,
        setData
    ]
}

export default useGlobalState