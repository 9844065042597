import { CalendarContainer } from 'react-datepicker'
import { getColorFromAppState } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const DatePickerRoot = styled.div<{
  $headerBackgroundColor: AppColor
  $headerTextColor: AppColor
  $bodyTextColor: AppColor
  $daySelectedBackgroundColor: AppColor
  $daySelectedTextColor: AppColor
  $dayFocusBackgroundColor: AppColor
  $dayFocusTextColor: AppColor
  $dayTodayBackgroundColor: AppColor
  $dayTodayTextColor: AppColor
}>`
  ${tw`w-full`}
  .react-datepicker__header--custom {
    ${tw`border-none text-normal font-normal`}
    background-color: ${({ $headerBackgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $headerBackgroundColor })
    }};
    color: ${({ $headerTextColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $headerTextColor })
    }};
  }

  .react-datepicker__day {
    ${tw`text-small font-normal inline-flex justify-center items-center rounded-full`}
    color: ${({ $bodyTextColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $bodyTextColor })
    }};
    width: 1.7rem;
    height: 1.7rem;

    &.react-datepicker__day--today {
      color: ${({ $dayTodayTextColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $dayTodayTextColor })
    }};
      background-color: ${({ $dayTodayBackgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $dayTodayBackgroundColor })
    }};
    }

    &.react-datepicker__day--keyboard-selected {
      color: ${({ $dayFocusTextColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $dayFocusTextColor })
    }};
      background-color: ${({ $dayFocusBackgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $dayFocusBackgroundColor })
    }};
    }

    &.react-datepicker__day--selected {
      color: ${({ $daySelectedTextColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $daySelectedTextColor })
    }};
      background-color: ${({ $daySelectedBackgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $daySelectedBackgroundColor })
    }};
    }
  }

  .react-datepicker__day-name {
    ${tw`py-1.5 text-normal font-normal`}
    background-color: ${({ $headerBackgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $headerBackgroundColor })
    }};
    color: ${({ $headerTextColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $headerTextColor })
    }};
  }

  .react-datepicker__triangle::before {
    ${tw`!border-t-0`}
  }
`

export const DatePickerCalendarContainer = styled(CalendarContainer)<{
  $bodyBackgroundColor: AppColor
}>`
  ${tw`border-none shadow-lg rounded-xl`}
  background-color: ${({ $bodyBackgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $bodyBackgroundColor })
    }};
`

export const DatePickerHeader = styled.div`
  ${tw`px-3 py-1 text-normal flex justify-between items-center`}
`

export const DatePickerHeaderYearSelect = styled.select`
  ${tw`border-none p-0 bg-transparent text-normal focus:ring-0 ml-1`}
  background-image: none;
`

export const DatePickerHeaderMonthChangeButton = styled.button<{
  $headerChangeMonthIconColor: AppColor
  $headerChangeMonthDisabledIconColor: AppColor
}>`
  svg {
    fill: ${({ $headerChangeMonthIconColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $headerChangeMonthIconColor })
    }} !important;
  }
  :disabled {
    svg {
      fill: ${({ $headerChangeMonthDisabledIconColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $headerChangeMonthDisabledIconColor })
    }} !important;
    }
  }
`