import { AvatarProps } from './Avatar.types'
import { AvatarRoot } from './Avatar.styles'
import React from 'react'

const Avatar = ({
    children,
    variant = 'text',
    color = { value: 'primary', level: '400' },
    textColor = { value: 'white' },
    size = 62,
    ...props
}: AvatarProps) => {
    const getSize = () => {
        switch (size) {
            case 'sm':
                return 62
            case 'lg':
                return 114
            default:
                return size
        }
    }

    return (
        <AvatarRoot
            {...props}
            $variant={variant}
            $color={color}
            $textColor={textColor}
            $size={getSize()}
            $biggerText={getSize() >= 80}
        >
            {children}
        </AvatarRoot>
    )
}

export default Avatar
