import { SimpleInterpolation } from 'styled-components'
import { TextFieldIconSize, TextFieldLabelVariant } from './TextField.types'
import { getColorFromAppState } from '../../../utils/Helper'
import tw, { css, styled } from 'twin.macro'

const TextFieldLabelVariantStyles: { outside: ReadonlyArray<SimpleInterpolation>; inside: ReadonlyArray<SimpleInterpolation> } = {
    inside: css`
      ${tw`absolute py-3.5 px-5 pointer-events-none`}
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    outside: css`${tw`whitespace-nowrap lg:w-2/4 relative lg:top-4`}`
}

export const TextFieldRoot = styled.div<{ $labelVariant: TextFieldLabelVariant }>`
  ${tw`w-full`}
  ${({ $labelVariant }) => {
        return $labelVariant === 'outside' && tw`flex flex-wrap gap-2 lg:justify-between lg:flex-nowrap lg:gap-5`
    }}
`

export const TextFieldContainer = styled.div`
  ${tw`relative w-full`}
`

export const TextFieldFloatingElements = styled.div<{ $disablePointerEventsNone?: boolean }>`
  ${tw`px-4 absolute h-full w-full flex items-center justify-between`}
  ${({ $disablePointerEventsNone }) => {
        return $disablePointerEventsNone ? '' : tw`pointer-events-none`
    }}
`

export const TextFieldLabel = styled.label<SCWithColorProps<{ $srOnly: boolean, $variant: TextFieldLabelVariant }>>`
  ${tw`text-normal font-normal`}
  ${({ $srOnly }) => {
        return $srOnly && tw`sr-only`
    }}
  color: ${getColorFromAppState};
  ${({ $variant }) => {
        return TextFieldLabelVariantStyles[$variant]
    }}
}
`

export const TextFieldInput = styled.input<{
    $backgroundColor: AppColor
    $focusColor: AppColor
    $textColor: AppColor
    $borderColor?: AppColor
    $placeholderColor: AppColor
    $invalid: boolean
    $hasLeftIcon: boolean
    $leftIconSize: TextFieldIconSize
    $hasRightIcon: boolean
    $rightIconSize: TextFieldIconSize
}>`
  ${tw`py-3.5 px-4 rounded-xl w-full text-normal font-normal border-2 border-transparent outline-none`}
  ${({ $hasLeftIcon, $leftIconSize }) => {
        return $hasLeftIcon && ($leftIconSize === 'sm' ? tw`!pl-9` : tw`!pl-11`)
    }}
  ${({ $hasRightIcon, $rightIconSize }) => {
        return $hasRightIcon && ($rightIconSize === 'sm' ? tw`!pr-9` : tw`!pr-11`)
    }}

  color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $textColor })
    }};
  background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $backgroundColor })
    }};
  border-color: ${({ $borderColor, ...props }) => {
        return $borderColor ?
            getColorFromAppState({ ...props, $color: $borderColor }) : 'transparent'
    }};
  
  :focus:not(:read-only) {
    ${tw`ring-2 !border-transparent`}
    --tw-ring-color: ${({ $focusColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $focusColor })
    }};
  }

  :read-only {
    ${tw`cursor-default`}
  }

  &:not(:placeholder-shown) ~ .label {
    ${tw`sr-only`}
  }

  & ~ .label {
    ${({ $hasLeftIcon, $leftIconSize }) => {
        return $hasLeftIcon && ($leftIconSize === 'sm' ? tw`!pl-10` : tw`!pl-12`)
    }}
    ${({ $hasRightIcon, $rightIconSize }) => {
        return $hasRightIcon && ($rightIconSize === 'sm' ? tw`!pr-10` : tw`!pr-12`)
    }}
  }

  ::placeholder {
    opacity: 0;
  }

  :disabled {
    opacity: 0.6;
  }

  :read-only:focus {
    ${tw`!outline-none !ring-0`}
    border-color: ${({ $borderColor, ...props }) => {
        return $borderColor ?
            getColorFromAppState({ ...props, $color: $borderColor }) : 'transparent'
    }} !important;
  }

  ${({ $invalid }) => {
        return $invalid && tw`!text-error-400 ring-2 !border-transparent !ring-error-400`
    }}
`

export const TextFieldValidationMessage = styled.strong<{$color?: AppColor}>`
  ${tw`flex mt-1 ml-2 text-small font-medium`}
  ${({ $color, ...props }) => {
        return $color ? `color: ${getColorFromAppState({ ...props, $color: $color })}` : tw`text-error-400`
    }}
`

export const TextFieldIconContainer = styled.span<{ $iconSize: TextFieldIconSize }>`
  ${tw`inline-flex items-center justify-center`}
  ${({ onClick }) => {
        return !!onClick && tw`pointer-events-auto`
    }}
  svg {
    ${({ $iconSize }) => {
        return $iconSize === 'sm' ? tw`h-4 w-4` : tw`h-6 w-6`
    }}
  }
`