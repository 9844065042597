import { Column, Grid, Row } from '../grid'
import { SimpleCardProps } from './SimpleCard.types'
import { SimpleCardRoot, SimpleCardTitle } from './SimpleCard.styles'
import React, { ElementType, ForwardedRef, forwardRef } from 'react'

const SimpleCard = <Element extends ElementType = 'div'>({
    as,
    children,
    title,
    ...props
}: SimpleCardProps<Element>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <SimpleCardRoot
            {...props}
            forwardedAs={as || 'div'}
            ref={ref}
        >
            <Grid>
                <Row>
                    <Column cols={12}>
                        <SimpleCardTitle>{title}</SimpleCardTitle>
                    </Column>
                </Row>
            </Grid>
            {children}
        </SimpleCardRoot>
    )
}

export default forwardRef(SimpleCard)
