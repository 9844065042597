import {
    FileUploadDialogFileListItemInfo,
    FileUploadDialogFileListItemInfoSubtitle,
    FileUploadDialogFileListItemInfoTitle,
    FileUploadDialogFileListItemInfoTitleContainer,
    FileUploadDialogFileListItemInfoTitleStatus,
    FileUploadDialogFileListItemRoot
} from './FileUploadDialog.styles'
import { FileUploadDialogFileListItemProps } from './FIleUploadDialog.types'
import { IBAApiResponseStatus, Invoice } from '../../../services/invoice/InvoiceService.types'
import { Line } from 'rc-progress'
import { truncateString } from '../../../utils/Helper'
import { useQueryClient } from '@tanstack/react-query'
import { useTheme } from 'styled-components'
import { useTranslation } from 'next-i18next'
import DoneIcon from '../../../assets/img/done-icon.svg'
import FileDoneIcon from '../../../assets/img/file-done-icon.svg'
import FileFailedIcon from '../../../assets/img/file-failed-icon.svg'
import FileLoadingIcon from '../../../assets/img/file-loading-icon.svg'
import FileProcessingIcon from '../../../assets/img/file-processing-icon.svg'
import React, { useState } from 'react'
import ReloadIcon from '../../../assets/img/reload-icon.svg'
import TrashIcon from '../../../assets/img/trash-icon.svg'
import useInvoiceGet from '../../../hooks/api/invoice/useInvoiceGet'
import useInvoiceUploadContext from '../../../hooks/invoice-upload/useInvoiceUploadContext'

const FileUploadDialogFileListItem = ({
    title,
    status,
    uploadPercentage = 100,
    id,
    invoiceId
}: FileUploadDialogFileListItemProps) => {
    const { t } = useTranslation('common')
    const queryClient = useQueryClient()
    const { colors: { primary: primaryColor, grey: greyColor } } = useTheme()
    const { retryFileUpload, deleteFile, setUploadingFileStatus } = useInvoiceUploadContext()
    const [normalizeStatus, setNormalizeStatus] = useState<IBAApiResponseStatus>()
    useInvoiceGet(
        invoiceId,
        invoiceId && status === 'processing',
        3000,
        (invoice: Invoice) => {
            const { normalizeStatus: invoiceNormalizeStatus, detectStatus, status: invoiceStatus } = invoice
            const isNormalizeStatusFailed = normalizeStatus === 'failed'
            const isDetectStatusFailed = detectStatus === 'failed'
            if (isNormalizeStatusFailed || isDetectStatusFailed) {
                setUploadingFileStatus(id, 'failed')
            }
            if (invoiceStatus === 'processed') {
                setUploadingFileStatus(id, 'finished')
            }
            if (invoiceStatus === 'error' || invoiceStatus === 'failed') {
                setUploadingFileStatus(id, 'failed')
            }
            setNormalizeStatus(invoiceNormalizeStatus)
            queryClient.setQueriesData<MakeAxiosPaginatedResponse<Invoice>>(
                ['invoices'],
                (prevData: MakeAxiosPaginatedResponse<Invoice>) => {
                    if (prevData) {
                        return {
                            ...prevData,
                            data: prevData.data.map((item: Invoice) => {
                                if (item.id === invoice.id) {
                                    return invoice
                                }
                                return item
                            })
                        }
                    }
                }
            )
        })

    const renderStatusIcon = () => {
        switch (status) {
            case 'finished':
                return (<FileDoneIcon/>)
            case 'processing':
                return (<FileProcessingIcon/>)
            case 'uploading':
                return (<FileLoadingIcon/>)
            case 'failed':
                return (<FileFailedIcon/>)
            default:
                return null
        }
    }

    const renderTitleStatus = () => {
        if (status === 'finished') {
            return (
                <DoneIcon className='fill-success-400'/>
            )
        }

        if (status === 'failed') {
            return (
                <>
                    <button
                        onClick={() => {
                            return retryFileUpload(id)
                        }}
                    >
                        <ReloadIcon/>
                    </button>
                    <button onClick={() => {
                        return deleteFile(id)
                    }}>
                        <TrashIcon/>
                    </button>
                </>
            )
        }
    }

    const renderNormalizeStatusText = () => {
        switch (normalizeStatus) {
            case 'ERR_CONVERT':
                return (t('uploadDialog.fileList.statuses.errConvert'))
            case 'ERR_NORM_NO_VERTEX':
                return (t('uploadDialog.fileList.statuses.errNormNoVertex'))
            case 'ERR_NORM_NO_VALID_VERTEX':
                return (t('uploadDialog.fileList.statuses.errNormNoValidVertex'))
            case 'ERR_CLASSIFY':
                return (t('uploadDialog.fileList.statuses.errClassify'))
            case 'ERR_NOT_INVOICE':
                return (t('uploadDialog.fileList.statuses.errNotInvoice'))
            case 'ERR_SAVE':
                return (t('uploadDialog.fileList.statuses.errSave'))
            default:
                return null
        }
    }

    const renderSubTitle = () => {
        if (normalizeStatus !== 'OK') {
            return (
                <FileUploadDialogFileListItemInfoSubtitle $failed={status === 'failed'}>
                    {renderNormalizeStatusText()}
                </FileUploadDialogFileListItemInfoSubtitle>
            )
        }
        return (
            <FileUploadDialogFileListItemInfoSubtitle $failed={status === 'failed'}>
                {t(`uploadDialog.fileList.statuses.${status}`)}
            </FileUploadDialogFileListItemInfoSubtitle>
        )
    }

    return (
        <FileUploadDialogFileListItemRoot>
            <div>
                {renderStatusIcon()}
            </div>
            <FileUploadDialogFileListItemInfo>
                <FileUploadDialogFileListItemInfoTitleContainer>
                    <FileUploadDialogFileListItemInfoTitle>
                        {truncateString(title, 26)}
                    </FileUploadDialogFileListItemInfoTitle>
                    <FileUploadDialogFileListItemInfoTitleStatus>
                        {renderTitleStatus()}
                    </FileUploadDialogFileListItemInfoTitleStatus>
                </FileUploadDialogFileListItemInfoTitleContainer>
                {renderSubTitle()}
                {status !== 'failed' &&
                    <Line
                        percent={uploadPercentage}
                        trailWidth={2.5}
                        trailColor={greyColor['200']}
                        strokeWidth={2.5}
                        strokeColor={primaryColor['400']}
                    />
                }
            </FileUploadDialogFileListItemInfo>
        </FileUploadDialogFileListItemRoot>
    )
}

export default FileUploadDialogFileListItem
