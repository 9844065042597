import { getColorFromAppState, makeTransitionCssClasses, pxToRem } from '../../../utils/Helper'
import styled from 'styled-components'
import tw from 'twin.macro'

const DialogTransitionClasses = makeTransitionCssClasses('dialog-overlay', {
    enter: tw`ease-out duration-300`,
    enterFrom: tw`opacity-0`,
    enterTo: tw`opacity-100`,
    leave: tw`ease-in duration-200`,
    leaveFrom: tw`opacity-100`,
    leaveTo: tw`opacity-0`
})

const DialogWindowTransitionClasses = makeTransitionCssClasses('dialog-window', {
    enter: tw`ease-out duration-300`,
    enterFrom: tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`,
    enterTo: tw`opacity-100 translate-y-0 sm:scale-100`,
    leave: tw`ease-in duration-200`,
    leaveFrom: tw`opacity-100 translate-y-0 sm:scale-100`,
    leaveTo: tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`
})

export const DialogRoot = styled.div`
  ${tw`relative z-10`}
  ${DialogTransitionClasses}
`

export const DialogOverlay = styled.div`
  ${tw`fixed inset-0 bg-black bg-opacity-75 transition-opacity`}
`

export const DialogContainer = styled.div`
  ${tw`fixed inset-0 z-10 overflow-y-auto`}
`

export const DialogWindow = styled.div`
  ${tw`flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0`}
  ${DialogWindowTransitionClasses}
`

export const DialogContent = styled.div<{$width: number}>`
  ${tw`relative transform overflow-hidden rounded-xl bg-white text-left transition-all sm:my-8 py-12 pl-10 pr-6`}
  width: ${({ $width }) => {
        return pxToRem($width)
    }}rem;
`

export const DialogContentTitle = styled.h6<SCWithColorProps>`
  ${tw`font-extrabold leading-7`}
  font-size: 1.25rem;
  color: ${getColorFromAppState};
`

export const DialogContentBody = styled.div`
  ${tw`mt-7`}
`

export const DialogContentActions = styled.div<{$biggerMargin: boolean}>`
  ${tw`flex items-center gap-7`}
  ${({ $biggerMargin }) => {
        return $biggerMargin ? tw`mt-20` : tw`mt-7`
    }}
`