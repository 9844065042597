import createAuthentication from '@dactyl-npm-packages/dactyl-auth-react'

export type User = {
    id: string
    name: string
    photoPath: string
    email: string
    companyId: string
    companyName: string
    city: string
    street: string
    buildingNumber: string
    postalCode: string
    createdAt: string
    updatedAt: string
    accountNumber: string
    bankCode: string
    iban: string
    twoFactorEnabled: boolean
}

export type RegisterRequestData = {
    name: string
    email: string
    password: string
    companyId: string
    companyName: string
    city: string
    street: string
    buildingNumber: string
    postalCode: string
    agreement: boolean
}

export type LoginRequestData = {
    email: string
    password: string
}

export type LoginVerificationRequestData = {
    email: string
    code: string
}

export type UpdateUserRequestData = {
    name: string
    companyId: string
    companyName: string
    city: string
    street: string
    buildingNumber: string
    postalCode: string
    accountNumber: string
    bankCode: string
    iban: string
    twoFactorEnabled: boolean
}

export type UpdateUserPasswordRequestData = {
    oldPassword: string
    password: string
}

export type ForgotPasswordRequestData = {
    email: string
}

export type ResetPasswordRequestData = {
    token: string
    email: string
    password: string
}

export type UserToken = {
    id: number
    name: string
    abilities: {}[]
    createdAt: string
    lastUsedAt: string | null
    expiresAt: string | null
}

export type TokenSchema = {
    plainTextToken: string
    expiresAt: string | null
}

export type CreateUserTokenRequest = {
    name: string
}

const {
    AuthContextProvider,
    useCurrentUser,
    useSignIn: useSignInVerification,
    useSignOut,
    useSignUp,
    useForgotPassword,
    useResetPassword,
    useCurrentUserUpdate,
    useCurrentUserUpdatePassword,
    useCurrentUserUpdateAvatar,
    Authenticated
} = createAuthentication<User>()


export {
    AuthContextProvider,
    useCurrentUser,
    useSignInVerification,
    useSignOut,
    useSignUp,
    useForgotPassword,
    useResetPassword,
    useCurrentUserUpdate,
    useCurrentUserUpdateAvatar,
    useCurrentUserUpdatePassword,
    Authenticated
}
