import { ButtonColor, ButtonVariant } from './Button.types'
import { CustomButtonColors } from '../custom-button/CustomButton.types'

const buttonColorVariantProps: Record<ButtonColor, Record<ButtonVariant, CustomButtonColors>> = {
    primary: {
        normal: {
            textColor: {
                value: 'white'
            },
            backgroundColor: {
                value: 'primary',
                level: '300'
            },
            hoverBackgroundColor: {
                value: 'primary',
                level: '200'
            },
            disabledBackgroundColor: {
                value: 'primary',
                level: '200'
            }
        },
        outline: {
            textColor: {
                value: 'primary',
                level: '300'
            },
            hoverTextColor: {
                value: 'primary',
                level: '200'
            },
            disabledTextColor: {
                value: 'primary',
                level: '200'
            },
            backgroundColor: {
                value: 'white'
            },
            borderColor: {
                value: 'primary',
                level: '300'
            },
            hoverBorderColor: {
                value: 'primary',
                level: '200'
            },
            hoverBackgroundColor: {
                value: 'white'
            },
            disabledBackgroundColor: {
                value: 'white'
            },
            disabledBorderColor: {
                value: 'primary',
                level: '200'
            },
            focusOutlineColor: {
                value: 'primary',
                level: '300'
            }
        },
        text: {
            textColor: {
                value: 'primary',
                level: '300'
            },
            hoverTextColor: {
                value: 'primary',
                level: '200'
            },
            disabledTextColor: {
                value: 'primary',
                level: '300'
            },
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            hoverBorderColor: 'transparent',
            hoverBackgroundColor: 'transparent',
            disabledBackgroundColor: 'transparent',
            focusOutlineColor: 'transparent'
        },
        filled: {
            textColor: {
                value: 'primary',
                level: '300'
            },
            hoverTextColor: {
                value: 'primary',
                level: '200'
            },
            disabledTextColor: {
                value: 'primary',
                level: '200'
            },
            backgroundColor: {
                value: 'white'
            },
            borderColor: {
                value: 'white'
            },
            hoverBorderColor: {
                value: 'white'
            },
            hoverBackgroundColor: {
                value: 'white'
            },
            disabledBackgroundColor: {
                value: 'white'
            },
            focusOutlineColor: 'transparent'
        }
    },
    secondary: {
        normal: {
            textColor: {
                value: 'white'
            },
            backgroundColor: {
                value: 'secondary',
                level: '400'
            },
            hoverBackgroundColor: {
                value: 'secondary',
                level: '300'
            },
            disabledBackgroundColor: {
                value: 'secondary',
                level: '400'
            }
        },
        outline: {
            textColor: {
                value: 'secondary',
                level: '400'
            },
            hoverTextColor: {
                value: 'secondary',
                level: '300'
            },
            disabledTextColor: {
                value: 'secondary',
                level: '400'
            },
            backgroundColor: {
                value: 'white'
            },
            borderColor: {
                value: 'secondary',
                level: '400'
            },
            hoverBorderColor: {
                value: 'secondary',
                level: '300'
            },
            hoverBackgroundColor: {
                value: 'white'
            },
            disabledBackgroundColor: {
                value: 'white'
            },
            focusOutlineColor: {
                value: 'secondary',
                level: '400'
            }
        },
        text: {
            textColor: {
                value: 'secondary',
                level: '400'
            },
            hoverTextColor: {
                value: 'secondary',
                level: '300'
            },
            disabledTextColor: {
                value: 'secondary',
                level: '400'
            },
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            hoverBorderColor: 'transparent',
            hoverBackgroundColor: 'transparent',
            disabledBackgroundColor: 'transparent',
            focusOutlineColor: 'transparent'
        },
        filled: {
            textColor: {
                value: 'secondary',
                level: '400'
            },
            hoverTextColor: {
                value: 'secondary',
                level: '300'
            },
            disabledTextColor: {
                value: 'secondary',
                level: '400'
            },
            backgroundColor: {
                value: 'white'
            },
            borderColor: {
                value: 'white'
            },
            hoverBorderColor: {
                value: 'white'
            },
            hoverBackgroundColor: {
                value: 'white'
            },
            disabledBackgroundColor: {
                value: 'white'
            },
            focusOutlineColor: 'transparent'
        }
    }
}

export default buttonColorVariantProps