import { Invoice } from '../../../services/invoice/InvoiceService.types'
import { getInvoice } from '../../../services/invoice/InvoiceService'
import { useCurrentUser } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import useAxios from '../../useAxios'

const useInvoiceGet = (
    id: string,
    enabled: boolean = true,
    refetchInterval: number | false = false,
    onSuccess?: (invoice: Invoice) => void
) => {
    const { isReady: isRouterReady } = useRouter()
    const { isReady: isCurrentUserReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    return useQuery(['invoice', id], async ({ signal }) => {
        if (!id) {
            return
        }
        return await getInvoice(axiosInstance, id, { signal })
    }, {
        enabled: enabled && (isRouterReady && isCurrentUserReady && isLogged),
        refetchInterval,
        onSuccess
    })
}

export default useInvoiceGet