import { LinkProps } from './Link.types'
import { LinkRoot } from './Link.styles'
import React, { ElementType, ForwardedRef, forwardRef } from 'react'

const Link = <Element extends ElementType = 'a'>({
    as,
    color = { value: 'primary', level: '300' },
    underline = true,
    bold = true,
    children,
    ...props
}: LinkProps<Element>, ref: ForwardedRef<Element>) => {
    return (
        <LinkRoot
            {...props}
            as={as || 'a'}
            ref={ref as unknown as ForwardedRef<HTMLAnchorElement>}
            $color={color}
            $underline={underline}
            $bold={bold}
        >
            {children}
        </LinkRoot>
    )
}

export default forwardRef(Link)
