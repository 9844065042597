import { getColorFromAppState } from '../../../utils/Helper'
import svgToTinyDataUri from 'mini-svg-data-uri'
import tw, { styled } from 'twin.macro'

const getCheckboxCheckedIconAsString = (color: string) => {
    return `
<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4.28986L3.62354 8L10 1.42659L8.61868 0L3.62354 5.1569L1.3716 2.86326L0 4.28986Z" fill="${color}"/>
</svg>
`
}

const getCheckboxIntermediateIconAsString = (color: string) => {
    return `
<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 1C10 0.447715 9.55229 0 9 0H1C0.447716 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H9C9.55228 2 10 1.55228 10 1Z" fill="${color}"/>
</svg>
`
}

export const CheckboxRoot = styled.div`
  ${tw`flex items-center`}
`

export const CheckboxInput = styled.input<SCWithColorProps<{ $checkedIconColor: AppColor }>>`
  ${tw`h-6 w-6 border-2 border-grey-300 rounded-sm`}
  color: ${getColorFromAppState};

  :checked {
    background-image: url("${({
        $checkedIconColor,
        ...props
    }) => {
        return svgToTinyDataUri(getCheckboxCheckedIconAsString(getColorFromAppState({
            ...props,
            $color: $checkedIconColor
        })))
    }}") !important;
    background-size: unset !important;
  }

  :indeterminate {
    background-image: url("${(props) => {
        return svgToTinyDataUri(getCheckboxIntermediateIconAsString(getColorFromAppState(props)))
    }}") !important;
    background-size: unset !important;
    color: ${({ $checkedIconColor, ...props }) => {
        return getColorFromAppState({
            ...props,
            $color: $checkedIconColor
        })
    }};
    border-color: ${getColorFromAppState} !important;
  }

  :focus {
    --tw-ring-color: ${getColorFromAppState};
  }
`

export const CheckboxLabel = styled.label<SCWithColorProps<{ $srOnly: boolean }>>`
  ${tw`text-normal font-medium !text-left`}
  ${({ $srOnly }) => {
        return $srOnly ? tw`sr-only` : tw`ml-3`
    }}
  color: ${getColorFromAppState};
`

export const CheckboxValidationMessage = styled.strong`
  ${tw`flex mt-1 ml-2 text-small font-medium text-error-400`}
`