import { Container } from '../../base'
import { SimpleCardContainerProps } from './SimpleCard.types'
import React from 'react'

const SimpleCardContainer = ({ children, ...props }: SimpleCardContainerProps) => {
    return (
        <Container {...props} spacing='md'>
            {children}
        </Container>
    )
}

export default SimpleCardContainer
