import { ColumnCols, ColumnResponsiveSize, OffsetCols, SCColumnProps, SCColumnResponsiveSettings } from './Column.types'
import { DefaultTheme, ThemedCssFunction } from 'styled-components'
import tw, { TwStyle, css, styled, theme } from 'twin.macro'

export const ColumnStyles: Record<ColumnCols, TwStyle> = {
    auto: tw`col`,
    1: tw`col-1`,
    2: tw`col-2`,
    3: tw`col-3`,
    4: tw`col-4`,
    5: tw`col-5`,
    6: tw`col-6`,
    7: tw`col-7`,
    8: tw`col-8`,
    9: tw`col-9`,
    10: tw`col-10`,
    11: tw`col-11`,
    12: tw`col-12`
}

export const OffsetColumnStyles: Record<OffsetCols, TwStyle> = {
    1: tw`offset-1`,
    2: tw`offset-2`,
    3: tw`offset-3`,
    4: tw`offset-4`,
    5: tw`offset-5`,
    6: tw`offset-6`,
    7: tw`offset-7`,
    8: tw`offset-8`,
    9: tw`offset-9`,
    10: tw`offset-10`,
    11: tw`offset-11`,
    auto: undefined
}

const getResponsiveSettings = ({ $cols, $offset }: SCColumnResponsiveSettings) => {
    return css`
  ${$cols && ColumnStyles[$cols]}
  ${$offset && OffsetColumnStyles[$offset]}
`
}

const responsiveMedia: Record<ColumnResponsiveSize, (
    settings: SCColumnResponsiveSettings
) => ReturnType<ThemedCssFunction<DefaultTheme>>> = {
    xs: (settings) => {
        return css`
      @media (min-width: ${theme`screens.xs`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    sm: (settings) => {
        return css`
      @media (min-width: ${theme`screens.sm`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    md: (settings) => {
        return css`
      @media (min-width: ${theme`screens.md`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    lg: (settings) => {
        return css`
      @media (min-width: ${theme`screens.lg`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    xl: (settings) => {
        return css`
      @media (min-width: ${theme`screens.xl`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    },
    xl2: (settings) => {
        return css`
      @media (min-width: ${theme`screens.2xl`}) {
        ${getResponsiveSettings(settings)}
      }
    `
    }
}

export const ColumnRoot = styled.div<SCColumnProps>`
  ${({ $cols, $xs, $sm, $md, $lg, $xl, $xl2 }) => {
        return css`
    ${ColumnStyles[$cols]}
    ${responsiveMedia.xs({ $cols: $xs })}
    ${responsiveMedia.sm({ $cols: $sm })}
    ${responsiveMedia.md({ $cols: $md })}
    ${responsiveMedia.lg({ $cols: $lg })}
    ${responsiveMedia.xl({ $cols: $xl })}
    ${responsiveMedia.xl2({ $cols: $xl2 })}
  `
    }}

  ${({ $offset, $xsOffset, $smOffset, $mdOffset, $lgOffset, $xlOffset, $xl2Offset }) => {
        return css`
    ${OffsetColumnStyles[$offset]}
    ${responsiveMedia.xs({ $offset: $xsOffset })}
    ${responsiveMedia.sm({ $offset: $smOffset })}
    ${responsiveMedia.md({ $offset: $mdOffset })}
    ${responsiveMedia.lg({ $offset: $lgOffset })}
    ${responsiveMedia.xl({ $offset: $xlOffset })}
    ${responsiveMedia.xl2({ $offset: $xl2Offset })}
  `
    }}
`