import { CheckboxInput, CheckboxLabel, CheckboxRoot, CheckboxValidationMessage } from './Checkbox.styles'
import { CheckboxProps } from './Checkbox.types'
import { mergeRefs } from 'react-merge-refs'
import React, { ForwardedRef, forwardRef, useEffect, useId, useRef } from 'react'

const Checkbox = ({
    id: idProp,
    indeterminate,
    color = { value: 'primary', level: '300' },
    labelColor = { value: 'primary', level: '400' },
    checkedIconColor = 'white',
    label,
    hideLabel,
    invalidMessage,
    ...props
}: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    const id = useId()
    const checkboxRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = indeterminate
        }
    }, [indeterminate])

    return (
        <div>
            <CheckboxRoot>
                <CheckboxInput
                    {...props}
                    ref={mergeRefs([checkboxRef, ref])}
                    id={idProp || id}
                    $color={color}
                    $checkedIconColor={checkedIconColor}
                    type='checkbox'
                />
                <CheckboxLabel
                    htmlFor={idProp || id}
                    $color={labelColor}
                    $srOnly={hideLabel}
                >
                    {label}
                </CheckboxLabel>
            </CheckboxRoot>
            {invalidMessage &&
                <CheckboxValidationMessage>
                    {invalidMessage}
                </CheckboxValidationMessage>
            }
        </div>
    )
}

export default forwardRef(Checkbox)
