import { ColumnFilter, ColumnFiltersState, PaginationState, SortingState } from '@tanstack/table-core'
import { DefaultTheme, FlattenSimpleInterpolation } from 'styled-components'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { IBAN } from 'ibankit'
import { MutableRefObject } from 'react'
import { RangeValue } from '../components/base/number-range/NumberRange'
import { TwStyle, css } from 'twin.macro'
import { UseFormSetError } from 'react-hook-form/dist/types/form'
import { composeIBAN } from 'ibantools'
import { format, parseISO } from 'date-fns'
import { size } from '@floating-ui/dom'
import ApiQueryBuilder from './api-query-builder/ApiQueryBuilder'
import FileType from 'magic-bytes.js'
import axios, { AxiosError } from 'axios'
import colorLib from 'color'

export type GenerateQRCodeParams = {
    iban: string
    price: number
    priceCurrency?: string
    message?: string
    specificSymbol?: string
    constantSymbol?: string
    variableSymbol?: string
    paymentType?: string
}

export type Coordinates = {
    x: number
    y: number
    width: number
    height: number
}

export type QrCodePaymentType = 'invoice' | 'payment'

export type InvoiceQrCodeData = {
    type: 'invoice'
    amount: string
    iban: string
    accountNumber?: string
    bankCode?: string
    currency: string
    deliveryDate: Date
    dueDate: Date
    transactionDate: Date
    taxDate: Date
    invoiceId: string
    issuerTaxId: string
    recipientTaxId: string
    message: string
    taxBase0: number
    taxBefore0: number
    taxDue: number
    issuerVatId: string
    recipientVatId?: string
    variableSymbol?: string
    software?: string
    crc32: string
}

export type PaymentQrCodeData = {
    type: 'payment'
    iban: string
    accountNumber?: string
    bankCode?: string
    amount: string
    currencyCode: string
    message?: string
    variableSymbol?: string
    specificSymbol?: string
    constantSymbol?: string,
    dueDate?: string
}

type BuildClassCallback = () => ({ [key: string]: boolean })

export const buildClasses = (className: string | string[] | { [key: string]: boolean }) => {
    return buildClassesWithDefault(className)
}

export const buildClassesWithDefault = (className: string | string[] | { [key: string]: boolean } | BuildClassCallback, defaultClassName?: string) => {
    if (defaultClassName) {
        defaultClassName = defaultClassName.trim()
    }
    if (typeof className === 'string') {
        return className + (defaultClassName && defaultClassName.length ? ` ${ defaultClassName}` : '')
    }
    if (Array.isArray(className)) {
        return className.join(' ')
    }
    return Object.entries(typeof className === 'function' ? className() : className)
        .filter(([, value]) => {
            return value
        }).map(([key]) => {
            return key
        }).join(' ')
        + (defaultClassName && defaultClassName.length > 0 ? ` ${ defaultClassName}` : '')
}

export const inverseColor = (color: string) => {
    return colorLib(color).isLight() ? '#000000' : '#ffffff'
}

export const getColorFromAppState = ({
    theme: { colors },
    $color,
    inverse
}: { theme: DefaultTheme, $color: AppColor, inverse?: boolean }): string => {
    let returnColor = colors.primary['400'] //Default return color
    if (typeof $color === 'string') {
        returnColor = $color
    } else {
        const color = colors[$color.value]
        if (typeof color === 'string') {
            returnColor = color
        } else {
            const level = $color.level //Simple way to convert number to string
            if (level in color) {
                returnColor = color[level]
            }
        }
    }
    return inverse ? inverseColor(returnColor) : returnColor
}

export const camelCaseToKebabCase = (str: string) => {
    return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
            : letter
    }).join('')
}

/*
*
* This method makes css transition classes for HeadlessUI Transition component (must be used in styled components)
* example generated classes:
*   - enter: {prefix}-transition-enter
*   - enterFrom: {prefix}-transition-enter-from
*   - enterFrom: {prefix}-transition-enter-to
*   ....
*
* */
export const makeTransitionCssClasses = (prefix: string, transitionSettings: {
    enter?: TwStyle | FlattenSimpleInterpolation
    enterFrom?: TwStyle | FlattenSimpleInterpolation
    enterTo?: TwStyle | FlattenSimpleInterpolation
    entered?: TwStyle | FlattenSimpleInterpolation
    leave?: TwStyle | FlattenSimpleInterpolation
    leaveFrom?: TwStyle | FlattenSimpleInterpolation
    leaveTo?: TwStyle | FlattenSimpleInterpolation
}, appendOperator = false) => {
    return Object.entries(transitionSettings).map(([key, value]) => {
        return (
            css`${appendOperator ? '&' : ''}.${prefix}-transition-${camelCaseToKebabCase(key)} {
      ${value}
    }`)
    })
}

export const parseCssValueAndUnit = (value: string) => {
    const numValue = parseFloat(value)
    const unitValue = value.replaceAll(`${numValue}`, '').trim()
    return {
        value: numValue,
        unit: unitValue
    }
}

export const pxToRem = (pixels: number | string) => {
    const pxToRemValue = 0.0625
    if (typeof pixels === 'string') {
        if (pixels.endsWith('rem')) {
            return pixels
        }
        return pxToRemValue * parseFloat(pixels)
    }
    return pxToRemValue * pixels
}

export const remToPx = (value: number | string) => {
    if (typeof value === 'string') {
        value = parseInt(value.replace('rem', ''))
    }
    let defaultFontSize = 16
    if (typeof window !== undefined) {
        defaultFontSize = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('font-size').replace('px', ''))
    }
    return Math.floor(value * defaultFontSize)
}

export const hexToRgba = (hex: string, alpha: number = 1) => {
    return colorLib(hex).alpha(alpha).toString()
}

export const isBoolean = (value?: unknown) => {
    return typeof value === 'boolean'
}

export const getPageOptions = (pageCount: number, page: number, maxNumberOfOptions: number) => {
    const options = []
    const pivot = Math.ceil(maxNumberOfOptions / 2)
    if (pageCount <= maxNumberOfOptions) {
        while (options.length <= pageCount) {
            options.push(options.length + 1)
        }
    } else if (page < pivot) {
        while (options.length < maxNumberOfOptions) {
            options.push(options.length + 1)
        }
    } else if (page > (pageCount - pivot)) {
        while (options.length < maxNumberOfOptions) {
            options.unshift(pageCount - options.length + 1)
        }
    } else {
        for (let i = page - (pivot - 1); options.length < maxNumberOfOptions; i++) {
            options.push(i + 1)
        }
    }
    return options
}

export const getFloatingAutoSizeMiddleware = () => {
    return size({
        apply({ availableWidth, availableHeight, elements }) {
            Object.assign(elements.floating.style, {
                minWidth: `${elements.reference.getBoundingClientRect().width}px`,
                maxWidth: `${availableWidth}px`,
                maxHeight: `${availableHeight}px`
            })
        }
    })
}

export const rangeBetweenNumbers = (start: number, stop?: number, stepSize = 1): number[] => {
    if (stop == null) {
        stop = start
        start = 1
    }

    const steps = (stop - start) / stepSize

    const set = []
    for (let step = 0; step <= steps; step++) {
        set.push(start + step * stepSize)
    }

    return set
}

export const buildDate = (builder: (current: Date) => Date) => {
    return builder(new Date())
}

export const getDOMElement = <ElementType extends Element = HTMLElement>(
    element: string | ElementType | MutableRefObject<ElementType | null>
): ElementType | null => {
    if (typeof element === 'string') {
        return document.querySelector(element)
    }
    if ('current' in element) {
        return element.current
    }
    return element
}

export const fixElementCollisions = (childX: number, childY: number, parentWidth: number, parentHeight: number, childWidth: number, childHeight) => {
    if ((childX + childWidth) > parentWidth && parentWidth > childWidth) {
        childX = parentWidth - childWidth
    }

    if ((childY + childHeight) > parentHeight && parentHeight > childHeight) {
        childY = parentHeight - childHeight
    }

    return {
        x: childX,
        y: childY
    }
}

export const handleFormErrorsFromAxios = <RequestData>(
    axiosError: AxiosError<{ message: string, errors?: object }> | Record<string, string[]>,
    setError: UseFormSetError<RequestData>,
    prefix?: string,
    suffix?: string
) => {
    let errors

    if (axios.isAxiosError(axiosError)) {
        errors = axiosError.response?.data?.errors
    } else {
        errors = axiosError
    }

    return errors && Object.entries(errors).forEach(([key, value]) => {
        return setError(`${prefix ? `${prefix}.` : ''}${key}${suffix ? `.${suffix}` : ''}` as FieldPath<RequestData>, { message: value as string })
    })
}

export const makeShortNameFromName = (name: string) => {
    if (!name) {
        return null
    }
    const nameData = name.split(' ')
    if (nameData.length === 2) {
        return `${nameData[0].charAt(0).toUpperCase()}${nameData[1].charAt(0).toUpperCase()}`
    }
    return nameData[0].charAt(0).toUpperCase()
}

export const removeKeysFromObject = <T extends object>(object: T) => {
    return <K extends keyof T>(...parts: Array<K>): Omit<T, K> => {
        return (Object.keys(object) as Array<keyof T>).reduce((acc, key) => {
            if (!parts.includes(key as any)) {
                acc[key] = object[key]
            }
            return acc
        }, {} as T)
    }
}

export const removeUndefinedValuesFromObject = <O extends object>(object: O) => {
    return Object.fromEntries(Object.entries(object).filter(([, value]) => {
        return !(value === undefined || value === null)
    }))
}

export const castNullValuesToUndefined = <O extends object>(object: O) => {
    return Object.fromEntries(Object.entries(object).map(([key, value]) => {
        if (value === null) {
            return [key, undefined]
        }
        return [key, value]
    }))
}

export const isNullOrUndefined = (val: unknown) => {
    return val === undefined || val === null
}

export type PaginationApiQueryBuilderParams = {
    filters: ColumnFiltersState
    sorts: SortingState
    pagination: PaginationState
}

export const makePaginationApiQueryBuilder = ({ filters, sorts, pagination }: PaginationApiQueryBuilderParams) => {
    const query = new ApiQueryBuilder()
    //Apply page
    query
        .page(pagination.pageIndex+1)
        .limit(pagination.pageSize)

    //Apply filters
    filters.forEach((item: ColumnFilter) => {
        if (item?.value?.['from'] || item?.value?.['to']) {
            const { from, to } = item.value as RangeValue
            const { id } = item
            if (isNumeric(from)) {
                query.fromNumber(id, parseInt(`${from}`))
            }
            if (isNumeric(to)) {
                query.toNumber(id, parseInt(`${to}`))
            }
            return
        }
        return query.where(item.id, item.value as string)
    })

    //Apply sorting
    query.sort(...sorts.map(item => {
        return `${item.desc ? '-' : ''}${item.id}`
    }))

    return query
}

export const formatCurrencyNumber = (amount: number, currency: string = 'CZK') => {
    const priceFormatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency
    })
    return priceFormatter.format(amount)
}

export const dateToISO = (date: Date) => {
    return format(date, 'yyyy-MM-dd\'T\'HH:mm:ssXX')
}

export const ISOtoDate = (isoDate: string) => {
    return parseISO(isoDate)
}

export const findFreeIdInArray = <Item extends object = object, Key extends keyof Item = keyof Item>(array: Item[], idKey: Key) => {
    if (typeof idKey !== 'string') {
        throw 'Id key must be a string.'
    }
    const key = idKey as string

    const sortedArray = array
        .slice()
        .sort( (a, b) => {
            return a[key] - b[key]
        })

    let previousId = 0
    for (const element of sortedArray) {
        if (element[idKey] != (previousId + 1)) {
            return previousId + 1
        }
        previousId = element[key]
    }

    return previousId + 1
}

export const calculatePercentage = (partialValue: number, totalValue: number) => {
    return Math.floor((100 * partialValue) / totalValue)
}

export const truncateString = (text: string, length: number) => {
    if (!text) {
        return
    }
    if (text.length < length) {
        return text
    }
    return `${text.substring(0, length)}...`
}

export const detectMimeType = async (blob: Blob): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.onloadend = async () => {
            const uint8Array = new Uint8Array(fileReader.result as ArrayBuffer)
            const fileType = FileType(uint8Array)

            if (fileType.length) {
                resolve(fileType[0].mime)
            } else {
                reject(new Error('Failed to determine MIME type.'))
            }
        }

        fileReader.onerror = () => {
            reject(new Error('Error while reading Blob.'))
        }

        fileReader.readAsArrayBuffer(blob)
    })
}

export const fileToDataUri = (file: File | Blob) => {
    return new Promise<string | undefined>((resolve) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            const result = event.target && event.target.result ? (event.target.result as string) : undefined
            resolve(result)
        }
        reader.readAsDataURL(file)
    })
}

const getFileExtension = (filenameOrUrl: string): string => {
    const isUrl = /^https?:\/\/|^\/\//i.test(filenameOrUrl)
    const path = isUrl ? new URL(filenameOrUrl).pathname : filenameOrUrl
    const dotIndex = path.lastIndexOf('.')

    if (dotIndex === -1) {
        return ''
    }

    return path.substring(dotIndex + 1)
}


export const isImageOrPdf = async (input: Blob | string): Promise<'image' | 'pdf' | 'unknown'> => {
    let mimeType = ''

    if (typeof input === 'string') {
        const fileExtension = getFileExtension(input)
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'bmp':
            case 'webp':
            case 'svg':
                mimeType = `image/${fileExtension}`
                break
            case 'pdf':
                mimeType = 'application/pdf'
                break
            default:
                mimeType = ''
        }
    } else {
        mimeType = await detectMimeType(input)
    }

    if (mimeType.startsWith('image/')) {
        return 'image'
    } else if (mimeType === 'application/pdf') {
        return 'pdf'
    } else {
        return 'unknown'
    }
}

export const relativeToAbsolute = (relativeCoordinates: Coordinates, refWidth: number, refHeight: number, readOnly: boolean = false) => {
    const border = {
        x: readOnly ? 7 : 5,
        y: readOnly ? 5 : 3,
        width: readOnly ? 14 : 10,
        height: readOnly ? 10 : 6
    }

    return {
        x: ((relativeCoordinates.x / 100) * refWidth) - border.x,
        y: ((relativeCoordinates.y / 100) * refHeight) - border.y,
        width: ((relativeCoordinates.width / 100) * refWidth) + border.width,
        height: ((relativeCoordinates.height / 100) * refHeight) + border.height
    }
}

export const absoluteToRelative = (absoluteCoordinates: Coordinates, refWidth: number, refHeight: number, readOnly: boolean = false) => {
    const border = {
        x: readOnly ? 7 : 5,
        y: readOnly ? 5 : 3,
        width: readOnly ? 14 : 10,
        height: readOnly ? 10 : 6
    }

    return {
        x: ((absoluteCoordinates.x + border.x) / refWidth) * 100,
        y: ((absoluteCoordinates.y + border.y) / refHeight) * 100,
        width: ((absoluteCoordinates.width - border.width) / refWidth) * 100,
        height: ((absoluteCoordinates.height - border.height) / refHeight) * 100
    }
}

export const getIbanFromBankCodeAndAccountNumber = (
    accountNumber: string,
    bankCode: string,
    countryCode: string = 'CZ'
) => {
    if (accountNumber.includes('-')) {
        const [prefix, base] = accountNumber.split('-')
        const basePadded = base.padStart(10, '0')
        const prefixPadded = prefix.padStart(6, '0')
        return composeIBAN({
            countryCode: countryCode,
            bban: `${bankCode}${prefixPadded}${basePadded}`
        })
    } else {
        const accountNumberPadded = accountNumber.padStart(16, '0')
        return composeIBAN({
            countryCode: countryCode,
            bban: `${bankCode}${accountNumberPadded}`
        })
    }
}


export const QRCodeStringPattern
    = 'SPD*1.0*ACC:{iban}*AM:{price}*CC:{priceCurrency}*MSG:{message}*X-KS:{constantSymbol}*X-SS:{specificSymbol}*X-VS:{variableSymbol}'

export const generateQrCode = (params: GenerateQRCodeParams) => {
    const { iban, price, variableSymbol } = params
    const priceCurrency = params?.priceCurrency || 'CZK'
    const message = params?.message || `QR PAYMENT ${params.variableSymbol}`
    const constantSymbol = params?.constantSymbol || '0000'
    const specificSymbol = params?.specificSymbol || '0000'
    return QRCodeStringPattern
        .replace('{iban}', iban)
        .replace('{price}', price.toString())
        .replace('{priceCurrency}', priceCurrency)
        .replace('{message}', message)
        .replace('{constantSymbol}', constantSymbol)
        .replace('{specificSymbol}', specificSymbol)
        .replace('{variableSymbol}', variableSymbol)
}

export const parseInvoiceQrString = (input: string, disableIbanValidation?: boolean) => {
    const fieldMapping = {
        'AM': 'amount',
        'ACC': 'iban',
        'CC': 'currency',
        'DD': 'deliveryDate',
        'DPPD': 'dueDate',
        'DT': 'transactionDate',
        'DUZP': 'taxDate',
        'ID': 'invoiceId',
        'INI': 'issuerTaxId',
        'INR': 'recipientTaxId',
        'MSG': 'message',
        'T0': 'taxBase0',
        'TB0': 'taxBefore0',
        'TD': 'taxDue',
        'VII': 'issuerVatId',
        'VIR': 'recipientVatId',
        'VS': 'variableSymbol',
        'X-SW': 'software',
        'CRC32': 'crc32'
    }

    const pairs = input.split('*')
    const result: InvoiceQrCodeData = {} as InvoiceQrCodeData

    pairs.forEach(pair => {
        const [key, ...valueParts] = pair.split(':')
        if (valueParts.length !== 0) {
            const value = valueParts.join(':')
            const mappedKey = fieldMapping[key] ? fieldMapping[key] : key
            switch(key) {
                case 'DD':
                case 'DPPD':
                case 'DT':
                case 'DUZP':
                    result[mappedKey] = new Date(Number(value.substring(0, 4)), Number(value.substring(4,2)) - 1, Number(value.substring(6,2)))
                    break
                case 'ACC':
                    const ibanString = value.split('+')[0]
                    let iban
                    if (!disableIbanValidation) {
                        iban = new IBAN(ibanString)
                        result['accountNumber'] = iban.getAccountNumber()
                        result['bankCode'] = iban.getBankCode()
                    }
                    result['iban'] = ibanString
                    break
                default:
                    result[mappedKey] = value
            }
        }
    })

    return result
}

export const parsePaymentQrString = (input: string, disableIbanValidation?: boolean) => {
    const fieldMapping = {
        'ACC': 'iban',
        'AM': 'amount',
        'CC': 'currencyCode',
        'MSG': 'message',
        'X-VS': 'variableSymbol',
        'X-SS': 'specificSymbol',
        'X-KS': 'constantSymbol',
        'DT': 'dueDate',
        'PT': 'paymentType'
    }

    const pairs = input.split('*')
    const result: PaymentQrCodeData = {} as PaymentQrCodeData

    pairs.forEach(pair => {
        const [key, ...valueParts] = pair.split(':')
        if (valueParts.length !== 0) {
            const value = valueParts.join(':')
            const mappedKey = fieldMapping[key] ? fieldMapping[key] : key
            switch (key) {
                case 'DT':
                    result[mappedKey] = new Date(Number(value.substring(0, 4)), Number(value.substring(4,2)) - 1, Number(value.substring(6,2)))
                    break
                case 'ACC':
                    const ibanString = value.split('+')[0]
                    let iban
                    if (!disableIbanValidation && IBAN.isValid(ibanString)) {
                        iban = new IBAN(ibanString)
                        result['accountNumber'] = iban.getAccountNumber()
                        result['bankCode'] = iban.getBankCode()
                        result['iban'] = ibanString
                    }
                    break
                default:
                    result[mappedKey] = value
                    break
            }
        }
    })

    return result
}

export const checkQrPaymentType = (input: string): QrCodePaymentType | 'unknown' => {
    if (input.toLowerCase().startsWith('spd')) {
        return 'payment'
    }
    if (input.toLowerCase().startsWith('sid')) {
        return 'invoice'
    }
    return 'unknown'
}

export const isNumeric = (str?: unknown) => {
    if (typeof str === 'number') {
        return true
    }
    if (typeof str !== 'string') {
        return false
    }
    return !isNaN(parseInt(str)) &&
        !isNaN(parseFloat(str))
}
