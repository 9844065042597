import { Button } from '../button'
import { UploadAreaDescription, UploadAreaRoot } from './UploadArea.styles'
import { UploadAreaProps } from './UploladArea.types'
import { useDropzone } from 'react-dropzone'
import React from 'react'

const UploadArea = ({
    backgroundColor = { value: 'primary', level: '100' },
    borderColor = { value: 'primary', level: '300' },
    buttonColor = 'primary',
    buttonIcon,
    buttonLabel,
    buttonVariant = 'normal',
    description,
    secondDescription,
    className,
    style,
    ...props
}: UploadAreaProps) => {
    const { getRootProps, getInputProps, open } = useDropzone(props)

    return (
        <UploadAreaRoot
            className={className}
            style={style}
            $backgroundColor={backgroundColor}
            $borderColor={borderColor}
            onClick={open}
            {...getRootProps()}
        >
            <input {...getInputProps()}/>
            <Button
                leftIcon={buttonIcon}
                color={buttonColor}
                variant={buttonVariant}
                onClick={open}
                type='button'
            >
                {buttonLabel}
            </Button>
            <UploadAreaDescription>
                {description}
            </UploadAreaDescription>
            <UploadAreaDescription>
                {secondDescription}
            </UploadAreaDescription>
        </UploadAreaRoot>
    )
}

export default UploadArea
