import { TabRendererOptions, TabType, TabsProps } from './Tabs.types'
import { TabsItem, TabsRoot } from './Tabs.styles'
import React from 'react'

const Tabs = <D extends TabType = TabType>({
    textColor = { value: 'primary', level: '300' },
    activeTextColor = { value: 'white' },
    activeBackgroundColor = { value: 'primary', level: '300' },
    items,
    selectedTab,
    onSelectTab,
    keyExtractor = (tab, index) => {
        return index
    },
    renderTabItem,
    ...props
}: TabsProps<D>) => {
    const getTabRendererOptions = (tab: D, index: number): TabRendererOptions => {
        if (selectedTab === index) {
            return {
                active: true,
                textColor: activeTextColor,
                backgroundColor: activeBackgroundColor,
                disabled: tab.disabled || false
            }
        }
        return {
            active: false,
            textColor,
            backgroundColor: 'transparent',
            disabled: tab.disabled || false
        }
    }
    
    return (
        <TabsRoot {...props}>
            {items.map((tab, index) => {
                return (
                    <TabsItem
                        key={keyExtractor(tab, index)}
                        onClick={() => {
                            return onSelectTab(index)
                        }}
                        disabled={tab.disabled}
                        $active={selectedTab === index}
                        $textColor={textColor}
                        $activeTextColor={activeTextColor}
                        $activeBackgroundColor={activeBackgroundColor}
                    >
                        {renderTabItem(tab, getTabRendererOptions(tab, index), index)}
                    </TabsItem>
                )
            })}
        </TabsRoot>
    )
}

export default Tabs
