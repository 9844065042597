import { FormProps } from './Form.types'
import React, { ForwardedRef, KeyboardEvent, forwardRef } from 'react'

const Form = ({ children, preventOnEnterPress, onKeyPress: onKeyPressProp, ...props }: FormProps, ref: ForwardedRef<HTMLFormElement>) => {
    const onKeyPress = (event: KeyboardEvent<HTMLFormElement>) => {
        if (preventOnEnterPress && event.key.toLowerCase() === 'enter') {
            event.preventDefault()
        }
        onKeyPressProp?.(event)
    }
    
    return (
        <form {...props} onKeyPress={onKeyPress} ref={ref}>
            {children}
        </form>
    )
}

export default forwardRef(Form)
