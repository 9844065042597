import { AxiosContextState, AxiosProviderProps } from './AxiosContext.types'
import { toast } from 'react-toastify'
import React, { createContext, useMemo } from 'react'
import axiosLib, { AxiosInstance } from 'axios'

export const createAxiosInstance = (baseURL) => {
    const instance = axiosLib.create({ baseURL })

    //Show toast on error
    instance.interceptors.response.use(undefined, error => {
        if (axiosLib.isAxiosError(error)) {
            toast(error.response.data.message, { type: 'error' })
        }
        return Promise.reject(error)
    })

    return instance
}
export const AxiosContext = createContext<AxiosContextState>({} as AxiosContextState)

export const AxiosProvider = ({ children, baseURL }: AxiosProviderProps) => {
    const axios = useMemo<AxiosInstance>(() => {
        return createAxiosInstance(baseURL)
    }, [baseURL])

    return (
        <AxiosContext.Provider value={{ axios }}>
            {children}
        </AxiosContext.Provider>
    )
}