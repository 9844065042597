import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

export const GlobalStyles = createGlobalStyle`
  html, body {
    font-family: 'Inter', sans-serif;
    ${tw`text-base font-normal w-full h-full`}
  }
  h1, h2, h3, h4 {
    ${tw`font-extrabold`}
  }
  
  /* Global input styles  */
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`