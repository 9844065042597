import { DropdownPositionType } from './Dropdown.types'
import { hexToRgba, makeTransitionCssClasses } from '../../../utils/Helper'
import tw, { TwStyle, styled } from 'twin.macro'

export const MenuItemsPositionStyles: Record<DropdownPositionType, TwStyle> = {
    'bottom-right': tw`origin-top-right left-0 mt-2`,
    'bottom-left': tw`origin-top-left right-0 mt-2`,
    'top-right': tw`origin-bottom-right left-0 bottom-full mb-2`,
    'top-left': tw`origin-bottom-left right-0 bottom-full mb-2`
}

const DropdownTransitionClasses = makeTransitionCssClasses('dropdown', {
    enter: tw`transition ease-out duration-100`,
    enterFrom: tw`transform opacity-0 scale-95`,
    enterTo: tw`transform opacity-100 scale-100`,
    leave: tw`transition ease-in duration-75`,
    leaveFrom: tw`transform opacity-100 scale-100`,
    leaveTo: tw`transform opacity-0 scale-95`
})

export const DropdownRoot = styled.div<{ $fullWidth: boolean }>`
  ${tw`relative inline-block text-left`}
  ${({ $fullWidth }) => {
        return $fullWidth ? tw`w-full` : ''
    }}
  ${DropdownTransitionClasses}
`

export const DropdownItems = styled.div<{ $position: DropdownPositionType }>`
  ${tw`absolute w-56 rounded-xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none p-4 z-20`}
  ${({ $position }) => {
        return MenuItemsPositionStyles[$position]
    }}
  box-shadow: 0 0.938rem 1.5rem ${({ theme: { colors } }) => {
        return hexToRgba(colors.primary['300'], 0.1)
    }};
`

export const DropdownGroupRoot = styled.div`
  ${tw`py-1`}
`

export const DropdownItemButton = styled.button<{ $disableHoverEffect: boolean }>`
  ${tw`flex items-center px-4 pb-2.5 pt-4 text-small font-medium w-full text-primary-300`}
  ${({ $disableHoverEffect }) => {
        return $disableHoverEffect ? tw`cursor-default` : tw`cursor-pointer`
    }}
`

export const DropdownItemButtonIconContainer = styled.span`
  ${tw`ml-2`}
  svg {
    ${tw`w-6 h-6`}
    fill: ${({ theme: { colors } }) => {
        return colors.primary['300']
    }};
    
    path {
      fill: ${({ theme: { colors } }) => {
        return colors.primary['300']
    }};
    }
  }
`