import { ContainerSpacing, SCContainerSideSpacing } from './Container.types'
import tw, { TwStyle, styled } from 'twin.macro'

const ContainerSpacingStyles: Record<SCContainerSideSpacing, Record<ContainerSpacing, TwStyle>> = {
    top: {
        sm: tw`mt-5`,
        md: tw`mt-10`,
        lg: tw`mt-28`
    },
    bottom: {
        sm: tw`mb-5`,
        md: tw`mb-10`,
        lg: tw`mb-28`
    },
    left: {
        sm: tw`ml-5`,
        md: tw`ml-10`,
        lg: tw`ml-28`
    },
    right: {
        sm: tw`mr-5`,
        md: tw`mr-10`,
        lg: tw`mr-28`
    }
}

export const ContainerRoot = styled.div<{
    $top?: SCContainerSideSpacing
    $bottom?: SCContainerSideSpacing
    $left?: SCContainerSideSpacing
    $right?: SCContainerSideSpacing
}>`
  ${({ $top }) => {
        return $top && ContainerSpacingStyles.top[$top]
    }}
  ${({ $bottom }) => {
        return $bottom && ContainerSpacingStyles.bottom[$bottom]
    }}
  ${({ $left }) => {
        return $left && ContainerSpacingStyles.left[$left]
    }}
  ${({ $right }) => {
        return $right && ContainerSpacingStyles.right[$right]
    }}
`