import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { DetectJsonRequest } from './InvoiceService.detectJson.types'
import {
    Invoice,
    InvoiceFileType,
    InvoicePaymentOrdersRequest,
    InvoiceStateUpdateRequest,
    InvoiceUpdateRequest
} from './InvoiceService.types'

export const getInvoicesPage = async (
    axiosInstance: AxiosInstance,
    query: string,
    config: AxiosRequestConfig = {}
): Promise<MakeAxiosPaginatedResponse<Invoice>> => {
    const { data } = await axiosInstance.get<MakeAxiosPaginatedResponse<Invoice>>(`invoices${query}`, config)
    return data
}

export const updateInvoice = async (
    axiosInstance: AxiosInstance,
    id: string,
    data: InvoiceUpdateRequest,
    config: AxiosRequestConfig = {}
): Promise<Invoice> => {
    const { data: { data: invoice } } = await axiosInstance
        .patch<MakeAxiosResponseData<Invoice>, AxiosResponse<MakeAxiosResponseData<Invoice>>, InvoiceUpdateRequest>(
            `invoices/${id}`,
            data,
            config
        )
    return invoice
}

export const updateInvoiceState = async (
    axiosInstance: AxiosInstance,
    id: string,
    data: InvoiceStateUpdateRequest,
    config: AxiosRequestConfig = {}
): Promise<Invoice> => {
    const { data: { data: invoice } } = await axiosInstance
        .patch<MakeAxiosResponseData<Invoice>, AxiosResponse<MakeAxiosResponseData<Invoice>>, InvoiceStateUpdateRequest>(
            `invoices/${id}/state`,
            data,
            config
        )
    return invoice
}

export const correctInvoice = async (
    axiosInstance: AxiosInstance,
    id: string,
    data: DetectJsonRequest,
    config: AxiosRequestConfig = {}
): Promise<Invoice> => {
    const { data: { data: invoice } } = await axiosInstance
        .post<MakeAxiosResponseData<Invoice>, AxiosResponse<MakeAxiosResponseData<Invoice>>, DetectJsonRequest>(
            `invoices/${id}/correct`,
            data,
            config
        )
    return invoice
}

export const deleteInvoice = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    return await axiosInstance.delete(`invoices/${id}`, config)
}

export const getInvoice = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<Invoice> => {
    const { data: { data: invoice } } = await axiosInstance.get<MakeAxiosResponseData<Invoice>>(`invoices/${id}`, config)
    return invoice
}

export const getInvoiceFile = async (
    axiosInstance: AxiosInstance,
    id: string,
    fileType: InvoiceFileType = 'normalized',
    config: AxiosRequestConfig = {}
): Promise<Blob> => {
    config = {
        ...config,
        responseType: 'blob',
        params: {
            ...(config.params || {}),
            type: fileType
        }
    }

    const { data } = await axiosInstance.get<Blob>(`invoices/${id}/download`, config)
    return data
}

export const getInvoiceISDOCFile = async (
    axiosInstance: AxiosInstance,
    id: string,
    fileType: InvoiceFileType = 'normalized',
    config: AxiosRequestConfig = {}
): Promise<Blob> => {
    config = {
        ...config,
        responseType: 'blob',
        params: {
            ...(config.params || {}),
            type: fileType
        }
    }

    const response = await axiosInstance.get<Blob>(`invoices/${id}/isdoc`, config)
    return response.data

}

export const getInvoicesPaymentOrders = async (
    axiosInstance: AxiosInstance,
    data: InvoicePaymentOrdersRequest,
    config: AxiosRequestConfig = {}
): Promise<Blob> => {
    config = {
        ...config,
        responseType: 'blob'
    }
    const { data: responseData } = await axiosInstance.post<Blob>(
        'invoices/payment-orders-xml',
        data,
        config
    )
    return responseData
}

export const uploadInvoiceFile = async (
    axiosInstance: AxiosInstance,
    invoiceFile: File,
    config: AxiosRequestConfig = {}
): Promise<Invoice> => {
    const formData = new FormData()
    formData.set('file', invoiceFile)
    const { data: { data: invoice } } = await axiosInstance.post<MakeAxiosResponseData<Invoice>>(
        'invoices/upload',
        formData,
        {
            ...config,
            headers: {
                ...config.headers,
                'Content-Type': 'multipart/form-data'
            }
        }
    )
    return invoice
}

export const markInvoiceAsCredible = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<Invoice> => {
    const { data: { data: invoice } } = await axiosInstance
        .post<MakeAxiosResponseData<Invoice>, AxiosResponse<MakeAxiosResponseData<Invoice>>, DetectJsonRequest>(
            `invoices/${id}/mark-as-credible`,
            null,
            config
        )
    return invoice
}
