import { getColorFromAppState } from '../../../utils/Helper'
import { styled } from 'twin.macro'
import SimpleFileLoadingOriginalIcon from '../../../assets/img/simple-file-loading-icon.svg'

const ArchiveIcon = styled(SimpleFileLoadingOriginalIcon).withConfig({
    shouldForwardProp: (prop) => {
        return !(['color', 'circleColor', 'refreshColor'].includes(prop as string))
    }
})<{
    color?: AppColor
    circleColor?: AppColor
    refreshColor?: AppColor
}>`
  path:not(.refresh) {
    stroke: ${({ color = { value: 'primary', level: '300' }, ...props }) => {
        return getColorFromAppState({ ...props, $color: color })
    }};
  }

  circle.circle {
    fill: ${({ circleColor = { value: 'primary', level: '400' }, ...props }) => {
        return getColorFromAppState({ ...props, $color: circleColor })
    }};
  }

  path.refresh {
    fill: ${({ refreshColor = 'white', ...props }) => {
        return getColorFromAppState({ ...props, $color: refreshColor })
    }};
  }
`

export default ArchiveIcon