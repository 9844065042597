import React, { PropsWithChildren, useEffect, useState } from 'react'

const ClientRender = ({ children }: PropsWithChildren) => {
    const [initializedPage, setInitializedPage] = useState<boolean>(false)

    useEffect(() => {
        if (typeof window === 'undefined') {
            return
        }
        setInitializedPage(true)
    }, [])

    if (!initializedPage) {
        return null
    }

    return (
        <>
            {children}
        </>
    )
}

export default ClientRender
