import { Button, Column, Container, Form, Row } from '../../base'
import { CenteredFormCardContainer, CenteredFormCardHeader, CenteredFormCardRoot } from './CenteredFormCard.styles'
import { CenteredFormCardProps } from './CenteredFormCard.types'
import { Grid } from '../grid'
import React, { ForwardedRef, forwardRef } from 'react'

const CenteredFormCard = ({
    title,
    header,
    footer,
    description,
    submitLabel,
    children,
    submitButtonProps = {},
    loading,
    ...props
}: CenteredFormCardProps, ref: ForwardedRef<HTMLFormElement>) => {
    const getSubmitButtonProps = () => {
        if (loading) {
            return {
                ...submitButtonProps,
                loading: loading
            }
        }
        return submitButtonProps
    }
    
    return (
        <CenteredFormCardRoot>
            <CenteredFormCardContainer>
                <Grid>
                    <Row>
                        {header &&
                            <Column cols={12}>
                                <CenteredFormCardHeader>
                                    {header}
                                </CenteredFormCardHeader>
                            </Column>
                        }
                        <Column cols={12}>
                            {title}
                        </Column>
                        {description &&
                            <Column cols={12}>
                                {description}
                            </Column>
                        }
                    </Row>
                </Grid>
                <Form {...props} ref={ref}>
                    <Container spacing='md'>
                        {children}
                    </Container>
                    <Container spacing='md'>
                        <Grid>
                            <Row>
                                <Column cols={12}>
                                    <Button type='submit' width='full-width' {...getSubmitButtonProps()}>
                                        {submitLabel}
                                    </Button>
                                </Column>
                                {footer &&
                                    <Column cols={12}>
                                        {footer}
                                    </Column>
                                }
                            </Row>
                        </Grid>
                    </Container>
                </Form>
            </CenteredFormCardContainer>
        </CenteredFormCardRoot>
    )
}

export default forwardRef(CenteredFormCard)
