import {
    FileUploadDialogFileListHeaderActionsContainer,
    FileUploadDialogFileListHeaderActionsRoot, FileUploadDialogFileListHeaderActionsTitle,
    FileUploadDialogFileListHeaderActionsTitleContainer
} from './FileUploadDialog.styles'
import { FileUploadDialogFileListHeaderActionsProps } from './FIleUploadDialog.types'
import CloseIcon from '../../../assets/img/close-icon.svg'
import DoneIcon from '../../../assets/img/done-icon.svg'
import LoadingSpinIcon from '../../../assets/img/loading-spin-icon.svg'
import MinimizeIcon from '../../../assets/img/minimize-icon.svg'
import React, { HTMLAttributes, MouseEvent } from 'react'

const FileUploadDialogFileListHeaderActions = ({
    onMinimize,
    done,
    onClose,
    title,
    ...props
}: FileUploadDialogFileListHeaderActionsProps) => {
    const clickMinimize = (event: MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation()
        onMinimize?.()
    }

    const clickClose = (event: MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation()
        onClose?.()
    }

    const renderCloseButton = () => {
        return (
            <button className='text-white' onClick={clickClose}>
                <CloseIcon className='fill-white'/>
            </button>
        )
    }

    const renderMinimizeButton = () => {
        return (
            <button onClick={clickMinimize}>
                <MinimizeIcon className='fill-white'/>
            </button>
        )
    }

    const renderActionIcon = () => {
        if (done) {
            return <DoneIcon/>
        }
        return <LoadingSpinIcon className='animate-spin' />
    }

    return (
        <FileUploadDialogFileListHeaderActionsRoot {...props as HTMLAttributes<HTMLDivElement>}>
            <FileUploadDialogFileListHeaderActionsTitleContainer>
                {renderActionIcon()}
                <FileUploadDialogFileListHeaderActionsTitle>
                    {title}
                </FileUploadDialogFileListHeaderActionsTitle>
            </FileUploadDialogFileListHeaderActionsTitleContainer>
            <FileUploadDialogFileListHeaderActionsContainer>
                {renderMinimizeButton()}
                {renderCloseButton()}
            </FileUploadDialogFileListHeaderActionsContainer>
        </FileUploadDialogFileListHeaderActionsRoot>
    )
}

export default FileUploadDialogFileListHeaderActions

