import { getColorFromAppState } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const FullScreenUploadAreaRoot = styled.div<SCWithColorProps>`
  ${tw`fixed left-0 top-0 h-full w-full flex flex-col justify-center items-center z-20`}
  background-color: ${getColorFromAppState};
`

export const FullScreenUploadAreaTitle = styled.h6<SCWithColorProps>`
  ${tw`mt-16 text-h3 font-extrabold`}
  color: ${getColorFromAppState};
`
