import { AvatarVariant } from './Avatar.types'
import { FlattenSimpleInterpolation } from 'styled-components'
import { getColorFromAppState, pxToRem } from '../../../utils/Helper'
import tw, { TwStyle, css, styled } from 'twin.macro'

export const AvatarVariantStyles: Record<AvatarVariant, TwStyle | FlattenSimpleInterpolation> = {
    text: tw`flex justify-center items-center`,
    image: css`
      img {
        ${tw`rounded-full w-full h-full object-cover`}
      }
    `
}

export const AvatarRoot = styled.span<SCWithColorProps<{ $variant: AvatarVariant, $textColor: AppColor, $size: number, $biggerText: boolean }>>`
  ${tw`block rounded-full text-large font-bold`}
  width: ${({ $size }) => {
        return `${pxToRem($size)}rem`
    }};
  height: ${({ $size }) => {
        return `${pxToRem($size)}rem`
    }};
  background-color: ${getColorFromAppState};
  color: ${({ $textColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $textColor })
    }};
  ${({ $variant }) => {
        return AvatarVariantStyles[$variant]
    }}
  ${({ $biggerText }) => {
        return $biggerText && 'font-size: 1.875rem;'
    }}
`