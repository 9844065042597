import { Button } from '../button'
import { Column, Grid, Row } from '../grid'
import { Form } from '../form'
import { SimpleCard, SimpleCardContainer } from '../simple-card'
import { SimpleFormCardProps } from './SimpleFormCard.types'
import React, { ForwardedRef, forwardRef } from 'react'

const SimpleFormCard = ({
    children,
    submitLabel,
    submitButtonProps = {},
    enabledCancelButton,
    cancelLabel,
    cancelButtonProps = {},
    onCancel,
    loading,
    controlButtons,
    underControlButtons,
    ...props
}: SimpleFormCardProps, ref: ForwardedRef<HTMLFormElement>) => {
    const getSubmitButtonProps = () => {
        if (loading) {
            return {
                ...submitButtonProps,
                loading: loading
            }
        }
        return submitButtonProps
    }

    const renderControlButtonsRow = () => {
        if (!controlButtons) {
            return (
                <Column cols={12} lg={8}>
                    <Button width='full-width' type='submit' {...getSubmitButtonProps()}>
                        {submitLabel}
                    </Button>
                </Column>
            )
        }
        return (
            <Column cols={12}>
                <div className='flex space-x-5'>
                    <Button width='full-width' type='submit' {...getSubmitButtonProps()}>
                        {submitLabel}
                    </Button>
                    {controlButtons}
                </div>
            </Column>
        )
    }
    
    return (
        <SimpleCard
            {...props}
            as={Form}
            ref={ref as unknown as ForwardedRef<HTMLDivElement>}
        >
            {children}
            <SimpleCardContainer>
                <Grid>
                    <Row gutter={0}>
                        <Column cols={12} md={5} xl={4}>
                            <Row>
                                {renderControlButtonsRow()}
                                {underControlButtons}
                                {enabledCancelButton &&
                                    <Column cols={12} lg={4}>
                                        <Button
                                            width='full-width'
                                            type='button'
                                            variant='outline'
                                            onClick={onCancel}
                                            {...cancelButtonProps}
                                        >
                                            {cancelLabel}
                                        </Button>
                                    </Column>
                                }
                            </Row>
                        </Column>
                    </Row>
                </Grid>
            </SimpleCardContainer>
        </SimpleCard>
    )
}

export default forwardRef(SimpleFormCard)
