import { Float } from '@headlessui-float/react'
import { Listbox } from '@headlessui/react'
import {
    SelectButton,
    SelectFloatingElements,
    SelectIconContainer,
    SelectItems,
    SelectRoot,
    SelectValidationMessage
} from './Select.styles'
import { SelectProps } from './Select.types'
import { autoPlacement } from '@floating-ui/dom'
import { buildClasses, getFloatingAutoSizeMiddleware } from '../../../utils/Helper'
import ArrowDownIcon from '../../../assets/img/arrow-down-icon.svg'
import CloseIcon from '../../../assets/img/close-icon.svg'
import React, { Fragment } from 'react'

const Select = <I extends unknown>({
    backgroundColor = { value: 'white' },
    textColor = { value: 'primary', level: '400' },
    focusColor = { value: 'primary', level: '300' },
    borderColor,
    activeItemBackgroundColor = { value: 'grey', level: '200' },
    invalid = false,
    invalidMessage,
    label,
    labelColor = { value: 'grey', level: '400' },
    clearable = false,
    items = [],
    selectedItem,
    renderItem,
    itemKeyExtractor = (index) => {
        return index
    },
    getLabel,
    onSelectItem,
    leftIcon,
    leftIconSize = 'sm',
    onClickLeftIcon,
    rightIcon = (<ArrowDownIcon style={{ width: 16, height: 16 }}/>),
    rightIconSize = 'sm',
    onClickRightIcon,
    disabled,
    ...props
}: SelectProps<I>) => {
    const clearSelect = () => {
        return clearable && onSelectItem(undefined)
    }
    const placeholderColor = clearable && !selectedItem ? labelColor : textColor

    const renderLeftIcon = () => {
        return (
            <SelectIconContainer
                as={onClickLeftIcon ? 'button' : 'span'}
                onClick={onClickLeftIcon}
                $iconSize={leftIconSize}
            >
                {leftIcon}
            </SelectIconContainer>
        )
    }

    const renderRightIcon = () => {
        if (clearable && selectedItem) {
            return (
                <SelectIconContainer
                    as='button'
                    onClick={clearSelect}
                    $iconSize={rightIconSize}
                >
                    <CloseIcon/>
                </SelectIconContainer>
            )
        }
        if (rightIcon) {
            return (
                <SelectIconContainer
                    as={onClickRightIcon ? 'button' : 'span'}
                    onClick={onClickRightIcon}
                    $iconSize={rightIconSize}
                >
                    {rightIcon}
                </SelectIconContainer>
            )
        }
    }

    const renderButtonLabel = () => {
        if (clearable && !selectedItem) {
            return label
        }
        return getLabel(selectedItem)
    }

    const renderItems = () => {
        return (
            <Listbox.Options
                as={SelectItems}
                $backgroundColor={activeItemBackgroundColor}
                $focusColor={focusColor}
                $textColor={textColor}
            >
                {items.map((item, index) => {
                    return (
                        <Listbox.Option
                            as='li'
                            key={itemKeyExtractor(index, item)}
                            value={item}
                        >
                            {({ selected, active }) => {
                                return (
                                    <button type='button' className={buildClasses({ selected, active })}>
                                        {renderItem?.(index, item, active, selected) || getLabel(item)}
                                    </button>
                                )
                            }}
                        </Listbox.Option>
                    )
                })}
            </Listbox.Options>
        )
    }

    return (
        <div {...props}>
            <Listbox as={SelectRoot} value={selectedItem} onChange={onSelectItem} disabled={disabled}>
                <SelectFloatingElements>
                    {renderLeftIcon()}
                    {renderRightIcon()}
                </SelectFloatingElements>
                <Float
                    placement='bottom'
                    strategy='fixed'
                    offset={8}
                    leave='select-transition-leave'
                    leaveFrom='select-transition-leave-from'
                    leaveTo='select-transition-leave-to'
                    middleware={[
                        getFloatingAutoSizeMiddleware(),
                        autoPlacement({ allowedPlacements: ['bottom', 'top'] })
                    ]}
                >
                    <Listbox.Button as={Fragment}>
                        {({ open, disabled: buttonDisabled }) => {
                            return (
                                <SelectButton
                                    disabled={buttonDisabled}
                                    $active={open}
                                    $invalid={invalid}
                                    $backgroundColor={backgroundColor}
                                    $borderColor={borderColor}
                                    $textColor={placeholderColor}
                                    $focusColor={focusColor}
                                    $hasLeftIcon={!!leftIcon}
                                    $leftIconSize={leftIconSize}
                                    $hasRightIcon={!!rightIcon || clearable}
                                    $rightIconSize={rightIconSize}
                                >
                                    {renderButtonLabel()}
                                </SelectButton>
                            )
                        }}
                    </Listbox.Button>
                    {renderItems()}
                </Float>
            </Listbox>
            {invalid && invalidMessage &&
                <SelectValidationMessage>
                    {invalidMessage}
                </SelectValidationMessage>
            }
        </div>
    )
}

export default Select
