import { DropdownItems, DropdownRoot } from './Dropdown.styles'
import { DropdownProps } from './Dropdown.types'
import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, PropsWithChildren, ReactElement } from 'react'
import flattenChildren from 'react-keyed-flatten-children'

export const DropdownActivator = ({ children }: PropsWithChildren) => {
    return (
        <Menu.Button as={Fragment}>
            {children}
        </Menu.Button>
    )
}

const Dropdown = ({ children: childrenProp, position = 'bottom-right', fullWidth = false, ...props }: DropdownProps) => {
    const children = flattenChildren(childrenProp) as ReactElement[]
    const ActivatorComponent = React.Children.toArray(children).find((child) => {
        return (child as ReactElement | null)?.type === DropdownActivator
    })
    const ContentComponent = React.Children.toArray(children).filter((child) => {
        return (child as ReactElement | null)?.type !== DropdownActivator
    })

    return (
        <Menu {...props} as={DropdownRoot} $fullWidth={fullWidth}>
            {ActivatorComponent}
            <Transition
                as={Fragment}
                enter='dropdown-transition-enter'
                enterFrom='dropdown-transition-enter-from'
                enterTo='dropdown-transition-enter-to'
                leave='dropdown-transition-leave'
                leaveFrom='dropdown-transition-leave-from'
                leaveTo='dropdown-transition-leave-to'
            >
                <Menu.Items as={DropdownItems} $position={position}>
                    {ContentComponent}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

Dropdown.Activator = DropdownActivator

export default Dropdown
