import { ToastProps, ToastTypeColors } from './Toast.types'
import { ToastRoot } from './Toast.styles'
import React from 'react'

const defaultTypeColors: ToastTypeColors = {
    success: {
        backgroundColor: { value: 'success', level: '100' },
        textColor: { value: 'success', level: '400' },
        spinnerSecondaryColor: { value: 'white' }
    },
    warning: {
        backgroundColor: { value: 'warning', level: '100' },
        textColor: { value: 'warning', level: '400' },
        spinnerSecondaryColor: { value: 'white' }
    },
    error: {
        backgroundColor: { value: 'error', level: '100' },
        textColor: { value: 'error', level: '400' },
        spinnerSecondaryColor: { value: 'white' }
    },
    info: {
        backgroundColor: { value: 'white' },
        textColor: { value: 'primary', level: '300' },
        spinnerSecondaryColor: { value: 'primary', level: '100' }
    },
    default: {
        backgroundColor: { value: 'white' },
        textColor: { value: 'primary', level: '300' },
        spinnerSecondaryColor: { value: 'primary', level: '100' }
    }
}

//Docs: https://fkhadra.github.io/react-toastify/introduction
const Toast = ({
    typeColors = defaultTypeColors,
    position = 'bottom-left',
    ...props
}: ToastProps) => {
    return (
        <ToastRoot
            {...props as object}
            position={position}
            $typeColors={typeColors}
        />
    )
}

export default Toast
