import {
    FileUploadDialogShortInfoHeader,
    FileUploadDialogShortInfoHeaderActionsContainer, FileUploadDialogShortInfoHeaderDescription,
    FileUploadDialogShortInfoHeaderTitle,
    FileUploadDialogShortInfoHeaderTitleContainer,
    FileUploadDialogShortInfoRoot, FileUploadDialogShortInfoStatusDescription
} from './FileUploadDialog.styles'
import { FileUploadDialogShortInfoProps } from './FIleUploadDialog.types'
import { InvoiceUploadStateType } from '../../../hooks/invoice-upload/useInvoiceUpload.types'
import { Trans, useTranslation } from 'next-i18next'
import { calculatePercentage } from '../../../utils/Helper'
import AlertIcon from '../../../assets/img/alert-icon.svg'
import CloseIcon from '../../../assets/img/close-icon.svg'
import DoneIcon from '../../../assets/img/done-icon.svg'
import DotIcon from '../../../assets/img/dot-icon.svg'
import LoadingSpinIcon from '../../../assets/img/loading-spin-icon.svg'
import MaximizeIcon from '../../../assets/img/maximize-icon.svg'
import React, { MouseEvent, useMemo } from 'react'
import useInvoiceUploadContext from '../../../hooks/invoice-upload/useInvoiceUploadContext'

const FileUploadDialogShortInfo = ({
    onMaximize,
    data,
    title,
    uploadingStatus
}: FileUploadDialogShortInfoProps) => {
    const { t } = useTranslation('common')
    const { setFileDialogOpen } = useInvoiceUploadContext()

    const done = useMemo<boolean>(() => {
        return data.reduce((acc: boolean, currentValue: InvoiceUploadStateType) => {
            return acc && currentValue.status !== 'uploading'
        }, true)
    }, [data])

    const successFiles = useMemo<number>(() => {
        const items = data.filter((item: InvoiceUploadStateType) => {
            return item.status === 'finished'
        })
        return items.length
    }, [data])

    const uploadedFiles = useMemo<number>(() => {
        const items = data.filter((item: InvoiceUploadStateType) => {
            if (uploadingStatus === 'uploading') {
                return item.status !== 'uploading'
            }
            if (uploadingStatus === 'processing') {
                return item.status !== 'processing'
            }
        })
        return items.length
    }, [uploadingStatus, data])

    const failedFiles = useMemo<number>(() => {
        const items = data.filter((item: InvoiceUploadStateType) => {
            return item.status === 'failed'
        })
        return items.length
    }, [data])

    const uploadingPercentage = useMemo<number>(() => {
        const totalValue = data.length * 100
        return calculatePercentage(
            data.reduce((acc: number, currentValue: InvoiceUploadStateType) => {
                return acc + currentValue.uploadPercentage
            }, 0),
            totalValue
        )
    },
    [data])

    const clickClose = (event: MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation()
        setFileDialogOpen(false)
    }

    const renderCloseButton = () => {
        return (
            <button className='text-white' onClick={clickClose}>
                <CloseIcon className='fill-white'/>
            </button>
        )
    }

    const renderMaximizeButton = () => {
        return (
            <button
                onClick={(event) => {
                    event.stopPropagation()
                    onMaximize()
                }}>
                <MaximizeIcon className='fill-white'/>
            </button>
        )
    }

    const renderTotalItems = () => {
        const totalFiles = data.length
        if (done) {
            return t('uploadDialog.subtitle.done', { successFiles, totalFiles })
        }
        return (
            <Trans
                t={t}
                i18nKey='uploadDialog.subtitle.uploading'
                components={{ dot: <DotIcon tw='mx-2'/> }}
                values={{
                    uploadedFiles,
                    totalFiles,
                    uploadingPercentage
                }}
            />
        )
    }

    return (
        <FileUploadDialogShortInfoRoot $smallerPadding={false} className='dialog-dragging-border'>
            <FileUploadDialogShortInfoHeader>
                <FileUploadDialogShortInfoHeaderTitleContainer>
                    {done ? <DoneIcon/> : <LoadingSpinIcon className='animate-spin'/>}
                    <FileUploadDialogShortInfoHeaderTitle>
                        {title}
                    </FileUploadDialogShortInfoHeaderTitle>
                </FileUploadDialogShortInfoHeaderTitleContainer>
                <FileUploadDialogShortInfoHeaderActionsContainer>
                    {renderMaximizeButton()}
                    {renderCloseButton()}
                </FileUploadDialogShortInfoHeaderActionsContainer>
            </FileUploadDialogShortInfoHeader>
            <FileUploadDialogShortInfoHeaderDescription>
                {renderTotalItems()}
            </FileUploadDialogShortInfoHeaderDescription>
            {done && failedFiles &&
                <FileUploadDialogShortInfoStatusDescription>
                    <AlertIcon className='fill-white'/> {t('uploadDialog.doneFailedInfo', { failedItems: failedFiles })}
                </FileUploadDialogShortInfoStatusDescription>
            }
        </FileUploadDialogShortInfoRoot>
    )
}

export default FileUploadDialogShortInfo
