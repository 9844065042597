import { RowProps } from './Row.types'
import { RowRoot } from './Row.styles'
import React from 'react'

const Row = ({
    children,
    justify = 'start',
    xsJustify,
    smJustify,
    mdJustify,
    lgJustify,
    xlJustify,
    xl2Justify,
    align = 'start',
    xsAlign,
    smAlign,
    mdAlign,
    lgAlign,
    xlAlign,
    xl2Align,
    direction = 'row',
    xsDirection,
    smDirection,
    mdDirection,
    lgDirection,
    xlDirection,
    xl2Direction,
    wrap = 'wrap',
    xsWrap,
    smWrap,
    mdWrap,
    lgWrap,
    xlWrap,
    xl2Wrap,
    gutter = 5,
    gutterY = gutter,
    gutterX = gutter,
    xsGutterY,
    xsGutterX,
    smGutterY,
    smGutterX,
    mdGutterY,
    mdGutterX,
    lgGutterX,
    lgGutterY,
    xlGutterY,
    xlGutterX,
    xl2GutterY,
    xl2GutterX,
    autoWidth = false,
    ...props
}: RowProps) => {
    return (
        <RowRoot
            $direction={direction}
            $xsDirection={xsDirection}
            $smDirection={smDirection}
            $mdDirection={mdDirection}
            $lgDirection={lgDirection}
            $xlDirection={xlDirection}
            $xl2Direction={xl2Direction}
            $justify={justify}
            $xsJustify={xsJustify}
            $smJustify={smJustify}
            $mdJustify={mdJustify}
            $lgJustify={lgJustify}
            $xlJustify={xlJustify}
            $xl2Justify={xl2Justify}
            $align={align}
            $xsAlign={xsAlign}
            $smAlign={smAlign}
            $mdAlign={mdAlign}
            $lgAlign={lgAlign}
            $xlAlign={xlAlign}
            $xl2Align={xl2Align}
            $wrap={wrap}
            $xsWrap={xsWrap}
            $smWrap={smWrap}
            $mdWrap={mdWrap}
            $lgWrap={lgWrap}
            $xlWrap={xlWrap}
            $xl2Wrap={xl2Wrap}
            $gutterY={gutterY}
            $gutterX={gutterX}
            $xsGutterX={xsGutterX}
            $xsGutterY={xsGutterY}
            $smGutterX={smGutterX}
            $smGutterY={smGutterY}
            $mdGutterX={mdGutterX}
            $mdGutterY={mdGutterY}
            $lgGutterX={lgGutterX}
            $lgGutterY={lgGutterY}
            $xlGutterX={xlGutterX}
            $xlGutterY={xlGutterY}
            $xl2GutterX={xl2GutterX}
            $xl2GutterY={xl2GutterY}
            $autoWidth={autoWidth}
            {...props}
        >
            {children}
        </RowRoot>
    )
}

export default Row
