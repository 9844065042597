import { getColorFromAppState } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const UploadAreaRoot = styled.div<{
  $backgroundColor: AppColor
  $borderColor: AppColor
}>`
  ${tw`relative block w-full rounded-lg border-2 border-dashed py-7 text-center cursor-pointer`}
  ${tw`focus:outline-none focus:ring-2 focus:ring-offset-2`}

  :focus {
    --tw-ring-color: ${({ $borderColor, ...props }) => {
        return getColorFromAppState({ ...props, $color: $borderColor })
    }};
  }
  
  background-color: ${({ $backgroundColor, ...props }) => {
        return getColorFromAppState({ $color: $backgroundColor, ...props })
    }};
  border-color: ${({ $borderColor, ...props }) => {
        return getColorFromAppState({ $color: $borderColor, ...props })
    }};
`

export const UploadAreaDescription = styled.p`
  ${tw`mt-6 text-small font-medium`}
`