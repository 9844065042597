import { Button } from '../button'
import { ConfirmDialogProps } from './ConfirmDialog.types'
import { Portal } from 'react-portal'
import Dialog from '../dialog/Dialog'
import React, { useState } from 'react'

const ConfirmDialog = ({
    children,
    onConfirm,
    confirmLabel = 'OK',
    cancelLabel = 'Cancel',
    loading,
    ...props
}: ConfirmDialogProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const open = () => {
        return setOpenDialog(true)
    }
    const close = () => {
        return setOpenDialog(false)
    }

    const renderActions = () => {
        return (
            <>
                <Button color='primary' width={150} loading={loading} onClick={() => {
                    return onConfirm(close)
                }}>
                    {confirmLabel}
                </Button>
                <Button
                    color='primary'
                    variant='outline'
                    width={150}
                    onClick={() => {
                        return setOpenDialog(false)
                    }}
                    disabled={loading}
                >
                    {cancelLabel}
                </Button>
            </>
        )
    }

    return (
        <>
            {children(open)}
            <Portal>
                <Dialog
                    {...props}
                    opened={openDialog}
                    onClose={setOpenDialog}
                    actions={renderActions()}
                />
            </Portal>
        </>
    )
}

export default ConfirmDialog
