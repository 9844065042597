import 'react-datepicker/dist/react-datepicker.css'
import {
    DatePickerCalendarContainer,
    DatePickerHeader, DatePickerHeaderMonthChangeButton, DatePickerHeaderYearSelect,
    DatePickerRoot
} from './DatePicker.styles'
import { DatePickerProps } from './DatePicker.types'
import { TextField } from '../text-field'
import { addYears, format, getMonth, subYears } from 'date-fns'
import { buildDate, rangeBetweenNumbers } from '../../../utils/Helper'
import { useTranslation } from 'next-i18next'
import ArrowLeftIcon from '../../../assets/img/arrow-left-icon.svg'
import ArrowRightIcon from '../../../assets/img/arrow-right-icon.svg'
import ExternalDatePicker, {
    CalendarContainerProps,
    ReactDatePickerCustomHeaderProps
} from 'react-datepicker'
import React, { ForwardedRef, forwardRef } from 'react'

const DEFAULT_MIN_DATE = subYears(buildDate((current) => {
    return new Date(current.getFullYear(), 0, 1)
}), 120)
const DEFAULT_MAX_DATE = addYears(buildDate((current) => {
    return new Date(current.getFullYear(), 11, 31)
}), 10)

const DatePicker = ({
    children,
    label,
    textFieldProps,
    customInput,
    calendarContainer,
    renderCustomHeader,
    locale,
    dateFormat = 'dd.MM.yyyy',
    headerDateFormat = 'LLLL',
    minDate = DEFAULT_MIN_DATE,
    maxDate = DEFAULT_MAX_DATE,
    headerBackgroundColor = { value: 'primary', level: '400' },
    headerTextColor = 'white',
    headerChangeMonthIconColor = 'white',
    headerChangeMonthDisabledIconColor = { value: 'primary', level: '400' },
    bodyTextColor = { value: 'primary', level: '400' },
    bodyBackgroundColor = 'white',
    daySelectedBackgroundColor = { value: 'primary', level: '300' },
    daySelectedTextColor = 'white',
    dayFocusBackgroundColor = { value: 'primary', level: '200' },
    dayFocusTextColor = { value: 'primary', level: '400' },
    dayTodayBackgroundColor = { value: 'primary', level: '100' },
    dayTodayTextColor = { value: 'primary', level: '400' },
    ...props
}: DatePickerProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { i18n: { language: currentLang } } = useTranslation()
    const datePickerLocale = locale || require(`date-fns/locale/${currentLang}/index.js`)

    const renderTextField = () => {
        return customInput || (
            <TextField
                {...textFieldProps}
                label={label}
                containerClassName='relative -top-1'
            />
        )
    }

    const renderCalendarContainer = (calendarProps: CalendarContainerProps) => {
        return calendarContainer?.(calendarProps) || (
            <DatePickerCalendarContainer {...calendarProps} $bodyBackgroundColor={bodyBackgroundColor}/>
        )
    }

    const renderCalendarHeader = ({
        changeYear,
        changeMonth,
        nextMonthButtonDisabled,
        prevMonthButtonDisabled,
        date
    }: ReactDatePickerCustomHeaderProps) => {
        const renderSelectYear = () => {
            const years =
                rangeBetweenNumbers(minDate.getFullYear(), maxDate.getFullYear())
                    .sort((a, b) => {
                        return b - a
                    })
            return (
                <DatePickerHeaderYearSelect
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => {
                        return changeYear(parseInt(value))
                    }}
                >
                    {years.map(item => {
                        return (<option key={item} value={item}>{item}</option>)
                    })}
                </DatePickerHeaderYearSelect>
            )
        }

        return (
            <DatePickerHeader>
                <DatePickerHeaderMonthChangeButton
                    type='button'
                    disabled={prevMonthButtonDisabled}
                    onClick={() => {
                        return changeMonth(getMonth(date) - 1)
                    }}
                    $headerChangeMonthIconColor={headerChangeMonthIconColor}
                    $headerChangeMonthDisabledIconColor={headerChangeMonthDisabledIconColor}
                >
                    <ArrowLeftIcon/>
                </DatePickerHeaderMonthChangeButton>
                <div>
                    {format(date, headerDateFormat, { locale: datePickerLocale })}
                    {renderSelectYear()}
                </div>
                <DatePickerHeaderMonthChangeButton
                    type='button'
                    disabled={nextMonthButtonDisabled}
                    onClick={() => {
                        return changeMonth(getMonth(date) + 1)
                    }}
                    $headerChangeMonthIconColor={headerChangeMonthIconColor}
                    $headerChangeMonthDisabledIconColor={headerChangeMonthDisabledIconColor}
                >
                    <ArrowRightIcon/>
                </DatePickerHeaderMonthChangeButton>
            </DatePickerHeader>
        )
    }

    return (
        <DatePickerRoot
            ref={ref}
            $headerTextColor={headerTextColor}
            $headerBackgroundColor={headerBackgroundColor}
            $bodyTextColor={bodyTextColor}
            $dayFocusBackgroundColor={dayFocusBackgroundColor}
            $dayFocusTextColor={dayFocusTextColor}
            $daySelectedBackgroundColor={daySelectedBackgroundColor}
            $daySelectedTextColor={daySelectedTextColor}
            $dayTodayBackgroundColor={dayTodayBackgroundColor}
            $dayTodayTextColor={dayTodayTextColor}
        >
            <ExternalDatePicker
                {...props}
                locale={datePickerLocale}
                customInput={renderTextField()}
                calendarContainer={renderCalendarContainer}
                renderCustomHeader={renderCustomHeader || renderCalendarHeader}
                dateFormat={dateFormat}
                minDate={minDate}
                maxDate={maxDate}
            >
                {children}
            </ExternalDatePicker>
        </DatePickerRoot>
    )
}

export default forwardRef(DatePicker)
