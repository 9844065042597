import { CardProps } from './Card.types'
import { CardRoot } from './Card.styles'
import React, { ElementType, ForwardedRef, forwardRef } from 'react'

const Card = <Element extends ElementType = 'div'>({
    as,
    children,
    color = { value: 'grey', level: '100' },
    ...props
}: CardProps<Element>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <CardRoot
            {...props}
            as={as || 'div'}
            ref={ref}
            $color={color}
        >
            {children}
        </CardRoot>
    )
}

export default forwardRef(Card)
