import { ButtonProps } from './Button.types'
import { CustomButton, CustomButtonProps } from '../custom-button'
import React, { ElementType, ForwardedRef, forwardRef } from 'react'
import buttonColorVariantProps from './Button.utils.variants'

const Button = <Element extends ElementType = 'button'>({
    as,
    variant = 'normal',
    color = 'primary',
    children,
    ...props
}: ButtonProps<Element>, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
        <CustomButton
            {...props as CustomButtonProps<Element>}
            {...buttonColorVariantProps[color][variant]}
            as={as || 'button'}
            ref={ref}
        >
            {children}
        </CustomButton>
    )
}

export default forwardRef(Button)
